/* src/styles/LaunchpadsOverview.css */

/* Custom Theme Variables */
:root {
  /* Default (dark mode) */
  --lo-bg-color: #1e1e33;
  --lo-primary-text: #ffffff;
  --lo-secondary-text: #a0a0c0;
  --lo-accent-color: #8a2be2;
  --lo-accent-hover: #b72be2;
  --lo-border-color: rgba(255, 255, 255, 0.1);
  --lo-card-bg: #2d2d4a;
  --lo-shadow: 0 8px 20px rgba(0, 0, 0, 0.5);
}

.light-mode {
  /* Light mode overrides */
  --lo-bg-color: #ffffff;
  --lo-primary-text: #333333;
  --lo-secondary-text: #666666;
  --lo-accent-color: #6a1b9a; /* Softer purple for light mode */
  --lo-accent-hover: #8a2be2;
  --lo-border-color: rgba(0, 0, 0, 0.1);
  --lo-card-bg: #f5f5f5;
  --lo-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
}

/* Container */
.launchpads-overview-container {
  width: 90%;
  max-width: 1200px;
  margin: 40px auto;
  padding: 32px;
  background: linear-gradient(135deg, var(--lo-bg-color), #2a2a4f);
  border-radius: 20px;
  box-shadow: var(--lo-shadow);
  color: var(--lo-primary-text);
  font-family: 'Inter', Arial, sans-serif;
  box-sizing: border-box;
  transition: all 0.3s ease; /* Smooth transition for theme changes */
}

.light-mode .launchpads-overview-container {
  background: linear-gradient(135deg, var(--lo-bg-color), #f0f0f0); /* Light mode gradient */
}

/* Header */
.launchpads-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  padding: 0 16px;
}

.overview-title {
  font-size: 2.25rem;
  font-weight: 700;
  margin: 0;
  color: var(--lo-primary-text);
}

.wallet-stats {
  display: flex;
  gap: 12px;
  font-size: 0.95rem;
  color: var(--lo-secondary-text);
}

.wallet-stats span {
  background: rgba(255, 255, 255, 0.05);
  padding: 8px 12px;
  border-radius: 12px;
  border: 1px solid var(--lo-border-color);
}

.light-mode .wallet-stats span {
  background: rgba(0, 0, 0, 0.05); /* Lighter background in light mode */
}

/* Grid Layout */
.launchpads-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 24px;
  width: 100%;
  padding: 0 16px;
}

/* Launchpad Card */
.launchpad-card {
  background: var(--lo-card-bg);
  border-radius: 16px;
  padding: 20px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  border: 1px solid transparent;
  transition: transform 0.3s ease, box-shadow 0.3s ease, border-color 0.3s ease;
  cursor: pointer;
  overflow: hidden;
}

.launchpad-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 20px rgba(138, 43, 226, 0.2);
}

.launchpad-card.live {
  border-color: var(--lo-accent-color); /* Purple for live */
}

.launchpad-card.upcoming {
  border-color: #ff9800; /* Orange for upcoming */
}

/* Header: Logo and Status */
.launchpad-header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
  position: relative;
}

.launchpad-logo {
  width: 80px;
  height: 80px;
  border-radius: 12px;
  object-fit: cover;
  border: 2px solid var(--lo-border-color);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.launchpad-status {
  position: absolute;
  top: 5px;
  right: 5px;
  background: var(--lo-card-bg);
  color: var(--lo-primary-text);
  padding: 6px 12px;
  font-size: 0.9rem;
  border-radius: 12px;
  text-transform: uppercase;
  border: 1px solid var(--lo-accent-color);
}

.launchpad-card.upcoming .launchpad-status {
  border-color: #ff9800;
  color: #ff9800;
}

.light-mode .launchpad-status {
  background: var(--lo-card-bg); /* Ensure card background adapts */
  color: var(--lo-primary-text); /* Ensure text color adapts */
}

/* Launchpad Content */
.launchpad-content {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.launchpad-name {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 8px;
  color: var(--lo-primary-text);
}

.launchpad-ticker {
  font-size: 1rem;
  color: var(--lo-secondary-text);
  margin-bottom: 8px;
}

.launchpad-price {
  font-size: 1.1rem;
  color: var(--lo-accent-color);
  margin-bottom: 12px;
}

.launchpad-dates {
  font-size: 0.9rem;
  color: var(--lo-secondary-text);
  margin-bottom: 16px;
}

.launch-date {
  margin: 4px 0;
}

/* Progress Bar */
.progress-bar {
  width: 100%;
  height: 12px;
  background: #33334d;
  border-radius: 8px;
  position: relative;
  margin-bottom: 16px;
}

.light-mode .progress-bar {
  background: #e5e5e5; /* Lighter progress bar background in light mode */
}

.progress-bar-fill {
  height: 100%;
  background: var(--lo-accent-color);
  border-radius: 8px;
  transition: width 0.5s ease;
}

.progress-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  font-weight: 600;
  color: var(--lo-primary-text);
}

/* Loading & No Launchpads */
.loading-spinner,
.no-launchpads {
  text-align: center;
  padding: 24px;
  font-size: 1.1rem;
  color: var(--lo-secondary-text);
}

/* Disclaimer */
.disclaimer {
  color: var(--lo-secondary-text);
  font-size: 0.9rem;
  text-align: center;
  margin-top: 24px;
  padding: 0 16px;
}

/* Mobile Responsiveness (≤430px) */
@media (max-width: 430px) {
  .launchpads-overview-container {
    margin: 16px auto;
    padding: 20px;
  }
  .launchpads-header {
    flex-direction: column;
    gap: 12px;
    padding: 0;
  }
  .overview-title {
    font-size: 1.75rem;
  }
  .wallet-stats {
    justify-content: center;
  }
  .launchpads-grid {
    grid-template-columns: 1fr;
    gap: 16px;
    padding: 0;
  }
  .launchpad-card {
    padding: 16px;
  }
  .launchpad-logo {
    width: 60px;
    height: 60px;
  }
  .launchpad-name {
    font-size: 1.25rem;
  }
  .launchpad-ticker,
  .launchpad-price {
    font-size: 0.9rem;
  }
  .launchpad-dates {
    font-size: 0.8rem;
  }
  .launchpad-status {
    top: 4px;
    right: 4px;
    padding: 4px 8px;
    font-size: 0.8rem;
  }
  .progress-bar {
    height: 10px;
  }
  .progress-text {
    font-size: 0.7rem;
  }
  .disclaimer {
    font-size: 0.8rem;
  }
}