/* Global Theme Variables */
:root {
  /* Default (dark mode) */
  --bg-color: #121212;
  --primary-text: #e0e0e0;
  --secondary-text: #b0b0b0;
  --accent-color: #8a2be2;
  --accent-hover: #b72be2;
  --modal-bg: #1e1e1e;
  --card-bg: #1a1a1a;
  --selected-border: #ffffff;
  --input-bg: #2c2c2c;
  --input-border: #444;
}

.light-mode {
  /* Light mode overrides */
  --bg-color: #ffffff;
  --primary-text: #333333;
  --secondary-text: #666666;
  --accent-color: #6a1b9a; /* Softer purple for light mode */
  --accent-hover: #8a2be2;
  --modal-bg: #f5f5f5;
  --card-bg: #f0f0f0;
  --selected-border: #333333; /* Darker border for light mode */
  --input-bg: #ffffff;
  --input-border: #ccc;
}

/* Global Styles */
body {
  background: linear-gradient(135deg, var(--bg-color), var(--card-bg));
  color: var(--primary-text);
  font-family: 'Space Mono', monospace;
  margin: 0;
  padding: 0;
  transition: all 0.3s ease; /* Smooth transition for theme changes */
}

a {
  text-decoration: none;
  color: inherit;
}

/* Page Layout */
.wallet-listings-page {
  padding: 20px;
  min-height: 100vh;
  background: var(--bg-color); /* Ensure the page background adapts to theme */
}

/* Bulk Action Buttons */
.wallet-button-group {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-bottom: 20px;
  background: var(--bg-color); /* Ensure background adapts to theme */
  padding: 10px; /* Add padding for better spacing */
  border-radius: 8px; /* Optional: for a cohesive look */
}

.wallet-tab-button {
  padding: 10px 15px;
  border: none;
  background-color: transparent;
  font-size: 16px;
  color: var(--secondary-text);
  display: flex;
  align-items: center;
  border-bottom: 2px solid transparent;
  transition: color 0.3s, border-bottom 0.3s;
  cursor: pointer;
}

.light-mode .wallet-tab-button {
  color: var(--secondary-text); /* Already #666 in light mode */
}

.wallet-tab-button i {
  margin-right: 8px;
}

.wallet-tab-button.active {
  color: var(--primary-text);
  border-bottom: 2px solid var(--accent-color);
}

.light-mode .wallet-tab-button.active {
  color: var(--primary-text); /* #333 for light mode */
  border-bottom: 2px solid var(--accent-color); /* #6a1b9a for light mode */
}

.wallet-tab-button:hover {
  color: var(--primary-text);
}

/* Activity Item (Listing Card) */
.activity-item-link {
  display: block;
  margin-bottom: 15px;
}

.activity-item {
  display: flex;
  align-items: center;
  background-color: var(--card-bg);
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  transition: transform 0.3s, box-shadow 0.3s;
}

.light-mode .activity-item {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.activity-item:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6);
}

.light-mode .activity-item:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.listings-nft-image {
  width: 70px;
  height: 70px;
  border-radius: 5px;
  margin-right: 15px;
  object-fit: cover;
}

.activity-details {
  flex: 1;
}

.activity-details p {
  margin: 0 0 5px;
  font-size: 0.95rem;
  line-height: 1.3;
  color: var(--primary-text);
}

.date {
  color: var(--secondary-text);
}

.asset-id {
  font-weight: bold;
  color: var(--primary-text);
}

.listed-price {
  font-weight: bold;
  color: var(--accent-color);
}

.marketplace-info {
  display: inline-flex;
  align-items: center;
  gap: 5px;
}

.marketplace {
  font-weight: bold;
  color: var(--secondary-text);
}

.marketplace-image {
  width: 16px;
  height: 16px;
}

/* Order Status */
.order-status {
  display: inline-flex;
  align-items: center;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 5px;
  margin-top: 5px;
  font-size: 0.9rem;
  width: 100px;
  text-align: center;
}

.status-active {
  background-color: rgba(0, 128, 0, 0.2);
  color: rgba(0, 128, 0, 1);
}

.light-mode .status-active {
  background-color: rgba(0, 128, 0, 0.1);
}

.status-cancelled {
  background-color: rgba(255, 0, 0, 0.2);
  color: rgba(255, 0, 0, 1);
}

.light-mode .status-cancelled {
  background-color: rgba(255, 0, 0, 0.1);
}

.status-claimed {
  background-color: rgba(0, 94, 255, 0.2);
  color: rgb(0, 94, 255);
}

.light-mode .status-claimed {
  background-color: rgba(0, 94, 255, 0.1);
}

.status-sold {
  background-color: rgba(255, 255, 0, 0.2);
  color: rgba(255, 255, 0, 1);
}

.light-mode .status-sold {
  background-color: rgba(255, 255, 0, 0.1);
  color: #666; /* Improve readability in light mode */
}

.status-expired {
  background-color: rgba(128, 128, 128, 0.2);
  color: rgba(128, 128, 128, 1);
}

.light-mode .status-expired {
  background-color: rgba(128, 128, 128, 0.1);
}

/* Pagination */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin-top: 20px;
}

.pagination button {
  background: linear-gradient(45deg, var(--accent-color), var(--accent-hover));
  border: none;
  padding: 5px 15px;
  border-radius: 20px;
  color: var(--primary-text);
  cursor: pointer;
  transition: opacity 0.3s;
}

.pagination button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.pagination span {
  color: var(--primary-text);
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.light-mode .modal-overlay {
  background: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background: var(--modal-bg);
  padding: 30px;
  border-radius: 10px;
  width: 90%;
  max-width: 400px;
  text-align: center;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.8);
}

.light-mode .modal-content {
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
}

.modal-content h2 {
  margin-bottom: 20px;
  color: var(--primary-text);
}

.modal-content p {
  margin-bottom: 20px;
  color: var(--secondary-text);
}

.modal-buttons {
  display: flex;
  justify-content: space-around;
  gap: 20px;
}

.modal-buttons button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.cancel-btn {
  background: #888;
  color: white;
}

.light-mode .cancel-btn {
  background: #cccccc;
  color: #333333;
}

.confirm-btn {
  background: linear-gradient(45deg, var(--accent-color), var(--accent-hover));
  color: var(--primary-text);
}

.confirm-btn:hover {
  background: var(--accent-hover);
}

.confirm-btn:disabled {
  background: #4a4a6a; /* Dark mode */
  cursor: not-allowed;
}

.light-mode .confirm-btn:disabled {
  background: #b0b0b0; /* Light mode */
  color: #666; /* Better contrast */
}

/* Responsive Adjustments */
@media (max-width: 430px) {
  .activity-item {
    flex-direction: column;
    align-items: flex-start;
  }
  .listings-nft-image {
    width: 30%;
    height: auto;
    display: block;
    margin: 0 auto 10px;
  }
  .activity-details p {
    font-size: 0.9rem;
  }
  .order-status {
    width: auto;
    margin-top: 10px;
  }
  .wallet-button-group {
    flex-direction: column;
    gap: 10px;
  }
  .pagination button {
    padding: 5px 10px;
    font-size: 0.9rem;
  }
}