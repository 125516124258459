:root {
  /* Default (dark mode) */
  --wp-bg-color: #0f0f1a;
  --wp-primary-text: #e0e0ff;
  --wp-secondary-text: #a0a0d0;
  --wp-accent-color: #7b00ff;
  --wp-accent-hover: #9b3bff;
  --wp-border-color: rgba(255, 255, 255, 0.1);
  --wp-card-bg: #1a1a2e;
  --wp-shadow: 0 8px 24px rgba(0, 0, 0, 0.4);
}

.light-mode {
  /* Light mode overrides */
  --wp-bg-color: #ffffff;
  --wp-primary-text: #333333;
  --wp-secondary-text: #666666;
  --wp-accent-color: #6a1b9a; /* Softer purple for light mode */
  --wp-accent-hover: #8a2be2;
  --wp-border-color: rgba(0, 0, 0, 0.1);
  --wp-card-bg: #f0f0f0;
  --wp-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
}

.wallet-overview-container {
  width: 90%;
  max-width: 1000px;
  margin: 40px auto;
  padding: 32px;
  background: var(--wp-bg-color);
  border-radius: 12px;
  box-shadow: var(--wp-shadow);
  color: var(--wp-primary-text);
  font-family: 'Inter', Arial, sans-serif;
  transition: all 0.3s ease; /* Smooth transition for theme changes */
}

.wallet-overview-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  padding: 0 16px;
}

.wallet-overview-title {
  font-size: 2.25rem;
  font-weight: 700;
  margin: 0;
  color: var(--wp-primary-text);
}

.wallet-stats {
  display: flex;
  gap: 12px;
  font-size: 0.95rem;
  color: var(--wp-secondary-text);
}

.wallet-stats span {
  background: rgba(255, 255, 255, 0.05);
  padding: 8px 12px;
  border-radius: 12px;
  border: 1px solid var(--wp-border-color);
}

.light-mode .wallet-stats span {
  background: rgba(0, 0, 0, 0.05); /* Lighter background in light mode */
}

.wallet-overview-tab-navigation {
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
  justify-content: center;
  padding: 0 16px;
}

.wallet-overview-tab-item {
  padding: 12px 24px;
  background: var(--wp-card-bg);
  color: var(--wp-primary-text);
  border: 1px solid var(--wp-border-color);
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.3s ease;
}

.light-mode .wallet-overview-tab-item {
  background: var(--wp-card-bg); /* Light gray background */
  color: var(--wp-primary-text); /* Dark text for contrast */
}

.wallet-overview-tab-item:hover {
  background: var(--wp-accent-hover);
  transform: translateY(-2px);
  box-shadow: 0 4px 10px rgba(123, 0, 255, 0.3);
}

.light-mode .wallet-overview-tab-item:hover {
  background: #e0e0e0; /* Slightly darker gray on hover */
  box-shadow: 0 4px 10px rgba(106, 27, 154, 0.2); /* Light mode hover shadow with accent color */
}

.wallet-overview-tab-item.active {
  background: var(--wp-accent-color);
  box-shadow: 0 4px 10px rgba(123, 0, 255, 0.3);
  transform: translateY(-2px);
}

.light-mode .wallet-overview-tab-item.active {
  background: var(--wp-accent-color); /* Keep accent color for active tab */
  box-shadow: 0 4px 10px rgba(106, 27, 154, 0.2); /* Light mode shadow */
  color: #ffffff; /* White text for contrast against purple background */
}

.wallet-overview-tab-icon {
  font-size: 1.1rem;
}

.wallet-overview-assets-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 16px;
  padding: 0 16px;
}

.wallet-overview-asset-item {
  display: flex;
  align-items: center;
  padding: 16px;
  background: var(--wp-card-bg);
  border-radius: 8px;
  border: 1px solid var(--wp-border-color);
  transition: all 0.3s ease;
}

.light-mode .wallet-overview-asset-item {
  background: var(--wp-card-bg); /* Light gray background */
}

.wallet-overview-asset-item:hover {
  transform: translateY(-4px);
  box-shadow: 0 6px 15px rgba(123, 0, 255, 0.2);
}

.light-mode .wallet-overview-asset-item:hover {
  box-shadow: 0 6px 15px rgba(106, 27, 154, 0.2); /* Light mode hover shadow with accent color */
}

.wallet-overview-asset-logo {
  width: 48px;
  height: 48px;
  object-fit: cover;
  border-radius: 8px;
  margin-right: 12px;
  border: 1px solid var(--wp-border-color);
}

.wallet-overview-asset-logo.placeholder-logo {
  background: #252540;
}

.light-mode .wallet-overview-asset-logo.placeholder-logo {
  background: #e0e0e0; /* Lighter placeholder in light mode */
}

.wallet-overview-asset-details {
  flex: 1;
}

.wallet-overview-asset-name {
  font-size: 1.1rem;
  font-weight: 600;
  color: var(--wp-primary-text);
  margin-bottom: 4px;
}

.wallet-overview-asset-balance {
  font-size: 0.9rem;
  color: var(--wp-secondary-text);
}

.wallet-overview-action-button {
  padding: 8px 16px;
  background: var(--wp-card-bg);
  color: var(--wp-primary-text);
  border: 1px solid var(--wp-accent-color);
  border-radius: 8px;
  font-size: 0.9rem;
  font-weight: 600;
  cursor: pointer;
  transition: background 0.3s ease, border-color 0.3s ease;
}

.light-mode .wallet-overview-action-button {
  background: #e0e0e0; /* Light gray background */
  color: var(--wp-primary-text); /* Dark text for contrast */
}

.wallet-overview-action-button:hover {
  background: var(--wp-accent-hover);
  border-color: var(--wp-accent-hover);
}

.light-mode .wallet-overview-action-button:hover {
  background: #d0d0d0; /* Slightly darker gray on hover */
  border-color: var(--wp-accent-hover);
}

.wallet-overview-empty {
  text-align: center;
  padding: 24px;
  font-size: 1.1rem;
  color: var(--wp-secondary-text);
}

@media (max-width: 430px) {
  .wallet-overview-container {
    margin: 8px auto;
    padding: 12px;
    width: 92%;
  }
  .wallet-overview-header {
    flex-direction: column;
    gap: 8px;
    padding: 0;
  }
  .wallet-overview-title {
    font-size: 1.5rem;
  }
  .wallet-stats {
    flex-direction: row;
    gap: 6px;
    flex-wrap: wrap;
    justify-content: center;
  }
  .wallet-stats span {
    padding: 6px 10px;
    font-size: 0.85rem;
    border-radius: 8px;
  }
  .wallet-overview-tab-navigation {
    flex-direction: column;
    gap: 8px;
    padding: 0;
    align-items: stretch;
  }
  .wallet-overview-tab-item {
    padding: 8px 16px;
    font-size: 0.9rem;
    border-radius: 6px;
    width: 100%;
    justify-content: center;
  }
  .wallet-overview-tab-icon {
    font-size: 0.9rem;
  }
  .wallet-overview-assets-list {
    grid-template-columns: 1fr;
    gap: 8px;
    padding: 0 8px;
  }
  .wallet-overview-asset-item {
    padding: 8px;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
  }
  .wallet-overview-asset-logo {
    width: 32px;
    height: 32px;
    margin-right: 0;
  }
  .wallet-overview-asset-details {
    width: 100%;
  }
  .wallet-overview-asset-name {
    font-size: 0.9rem;
  }
  .wallet-overview-asset-balance {
    font-size: 0.75rem;
  }
  .wallet-overview-action-button {
    width: 100%;
    padding: 4px 10px;
    font-size: 0.75rem;
  }
  .wallet-overview-empty {
    font-size: 0.85rem;
    padding: 12px;
  }
}