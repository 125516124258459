/* src/styles/Header.css */

/* Custom Theme Variables */
:root {
  /* Default (dark mode) */
  --c-bg-color: #0f0f1a;
  --c-primary-text: #e0e0ff;
  --c-secondary-text: #a0a0d0;
  --c-accent-color: #7b00ff;
  --c-accent-hover: #9b3bff;
  --c-border-color: rgba(255, 255, 255, 0.1);
  --c-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
}

.light-mode {
  /* Light mode overrides */
  --c-bg-color: #ffffff;
  --c-primary-text: #333333;
  --c-secondary-text: #666666;
  --c-accent-color: #6a1b9a; /* Softer purple for light mode */
  --c-accent-hover: #8a2be2;
  --c-border-color: rgba(0, 0, 0, 0.1);
  --c-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

/* Container */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px;
  background: var(--c-bg-color);
  color: var(--c-primary-text);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  height: 80px;
  z-index: 1000;
  box-shadow: var(--c-shadow);
  transition: all 0.3s ease; /* Smooth transition for theme changes */
}

/* Branding Styles */
.header-left {
  display: flex;
  align-items: center;
}

.brand-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--c-primary-text);
  gap: 8px; /* Tightened space between logo and text */
  transition: transform 0.3s ease;
}

.brand-link:hover {
  transform: scale(1.05); /* Subtle hover effect */
}

.brand-name {
  display: flex;
  align-items: center;
  gap: 4px; /* Reduced space between "Rare" and "Canvas" */
}

.rare {
  font-size: 1.8rem;
  font-weight: 700;
  color: var(--c-primary-text);
}

.canvas {
  font-size: 1.8rem;
  font-weight: 700;
  background: linear-gradient(to right, var(--c-accent-color), var(--c-accent-hover));
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.logo-icon {
  height: 45px;
  transition: transform 0.3s ease;
}

/* Hover effect for logo */
.logo-icon:hover {
  transform: scale(1.1);
}

/* Navigation Bar */
.header-nav {
  display: flex;
  align-items: center;
  gap: 20px;
}

.nav-link {
  text-decoration: none;
  color: var(--c-primary-text);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 10px;
  border-radius: 8px;
  transition: background 0.3s ease, color 0.3s ease;
  cursor: pointer;
  font-size: 0.8rem;
}

.nav-link:hover {
  background: #4a4a6a; /* Gray background on hover for a subtle effect */
  color: var(--c-primary-text); /* Retain theme text color */
}

.nav-link i {
  font-size: 1.5rem;
  margin-bottom: 2px;
}

.nav-label {
  font-size: 0.7rem;
  color: var(--c-secondary-text);
  white-space: nowrap;
}

/* Wallet Button */
.nav-link.wallet-button {
  background: transparent;
  border: 1px solid var(--c-accent-color);
}

.nav-link.wallet-button.connected {
  border-style: dashed;
}

.nav-link.wallet-button:hover {
  background: #4a4a6a; /* Gray background on hover */
  color: var(--c-primary-text); /* Retain theme text color */
  border-color: var(--c-accent-hover);
}

.nav-label.connected {
  color: var(--c-secondary-text);
}

/* Connected Wallet Button on Mobile */
@media (max-width: 430px) {
  .nav-link.wallet-button.connected {
    background: var(--c-accent-color);
    border: none;
  }

  .light-mode .nav-link.wallet-button.connected {
    background: #e0e0e0; /* Light gray background in light mode */
    color: #333333; /* Dark text for contrast */
  }
}

/* Desktop Connected Wallet Button */
@media (min-width: 431px) {
  .nav-link.wallet-button.connected {
    border-style: dashed;
  }
}

/* Responsive Styles */
@media (max-width: 768px) {
  .header {
    padding: 10px 20px;
    height: 70px;
  }

  .rare, .canvas {
    font-size: 1.5rem;
  }

  .logo-icon {
    height: 35px;
  }

  .brand-link {
    gap: 6px; /* Slightly tighter on tablet */
  }

  .brand-name {
    gap: 3px;
  }

  .header-nav {
    gap: 15px;
  }

  .nav-link {
    padding: 6px 8px;
  }

  .nav-link i {
    font-size: 1.3rem;
  }

  .nav-label {
    font-size: 0.65rem;
  }
}

@media (max-width: 430px) {
  .header {
    padding: 8px 10px;
    height: 60px;
    justify-content: center;
  }

  .header-left {
    display: none; /* Unchanged, assuming you want this behavior */
  }

  .header-nav {
    gap: 5px;
    justify-content: center;
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .nav-link {
    padding: 2px;
    background: none;
    width: 30px;
    margin: 0 2px;
    min-width: 0;
  }

  .nav-link i {
    font-size: 1rem;
    margin-bottom: 1px;
  }

  .nav-label {
    font-size: 0.5rem;
    max-width: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    display: none;
  }

  .nav-link.wallet-button {
    padding: 2px;
    width: 40px;
    min-width: 0;
  }

  .nav-link.wallet-button:hover {
    background: #4a4a6a; /* Gray background on hover */
    color: var(--c-primary-text); /* Retain theme text color */
    border-color: var(--c-accent-hover);
  }

  .nav-label.wallet-button {
    max-width: 30px;
    display: none;
  }
}