/* Spinner.css */
.spinner-container {
    display: grid;
    place-items: center;
    height: 100vh; /* Full viewport height */
  }
  
  
  .spinner {
    border: 8px solid rgba(0, 0, 0, 0.1);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border-left-color: #b72be2;
  
    animation: spin 1s ease infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  