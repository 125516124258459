/* Custom Theme Variables */
:root {
  --sf-bg-color: #1e1e33;
  --sf-primary-text: #ffffff;
  --sf-secondary-text: #a0a0c0;
  --sf-accent-color: #8a2be2;
  --sf-accent-hover: #b72be2;
  --sf-border-color: rgba(255, 255, 255, 0.1);
  --sf-card-bg: #2d2d4a;
  --sf-shadow: 0 8px 20px rgba(0, 0, 0, 0.5);
}

/* Light Mode Overrides */
.light-mode {
  --sf-bg-color: #ffffff;
  --sf-primary-text: #333333; /* Darker text for readability */
  --sf-secondary-text: #666666; /* Medium gray for secondary text */
  --sf-accent-color: #6a1b9a; /* Softer purple for light mode */
  --sf-accent-hover: #8a2be2;
  --sf-border-color: rgba(0, 0, 0, 0.1);
  --sf-card-bg: #f0f0f0; /* Light gray card background */
  --sf-shadow: 0 8px 20px rgba(0, 0, 0, 0.1); /* Lighter shadow */
}

/* Container */
.swap-container {
  width: 100%;
  max-width: 480px;
  margin: 40px auto;
  padding: 32px;
  background: linear-gradient(135deg, var(--sf-bg-color), var(--sf-card-bg));
  border-radius: 20px;
  box-shadow: var(--sf-shadow);
  color: var(--sf-primary-text);
  font-family: 'Inter', Arial, sans-serif;
  box-sizing: border-box;
  transition: all 0.3s ease; /* Smooth transition for theme changes */
}

.light-mode .swap-container {
  background: linear-gradient(135deg, var(--sf-bg-color), #e0e0e0); /* Softer light gradient */
  box-shadow: var(--sf-shadow); /* Ensure shadow adapts */
}

/* Header */
.swap-header {
  margin-bottom: 24px;
  padding: 0 16px;
}

.swap-title {
  font-size: 2rem;
  font-weight: 700;
  margin: 0 0 16px;
  color: var(--sf-primary-text);
  text-align: center;
}

.light-mode .swap-title {
  color: var(--sf-primary-text); /* Explicitly enforce #333333 in light mode */
}

/* Balance Info */
.swap-balance-info {
  margin-bottom: 24px;
  padding: 16px;
  background: var(--sf-card-bg);
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.light-mode .swap-balance-info {
  background: rgba(0, 0, 0, 0.05); /* Subtle gray background in light mode */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05); /* Lighter shadow in light mode */
}

.token-balance {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 12px;
}

.light-mode .token-balance {
  color: var(--sf-primary-text); /* Explicitly enforce #333333 in light mode */
}

.token-logo {
  width: 32px;
  height: 32px;
  border-radius: 8px;
  object-fit: cover;
}

.token-balance span {
  font-size: 1rem;
  color: var(--sf-primary-text); /* Ensure dark text in light mode */
}

/* Toggle Buttons */
.swap-toggle {
  display: flex;
  justify-content: center;
  gap: 12px;
  margin-bottom: 24px;
  flex-wrap: wrap;
}

.toggle-button {
  padding: 10px 20px;
  background: var(--sf-card-bg);
  color: var(--sf-primary-text);
  border: 1px solid var(--sf-border-color);
  border-radius: 12px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  transition: background 0.3s ease, border-color 0.3s ease;
  min-width: 140px;
  box-sizing: border-box;
}

.toggle-button.active {
  background: var(--sf-accent-color);
  border-color: var(--sf-accent-hover);
  color: var(--sf-primary-text);
}

.toggle-button:hover:not(.active) {
  background: #3a3a5a;
  border-color: var(--sf-accent-color);
}

.light-mode .toggle-button:hover:not(.active) {
  background: rgba(0, 0, 0, 0.05); /* Lighter hover effect in light mode */
}

/* Amount Buttons */
.amount-buttons {
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-bottom: 24px;
  flex-wrap: wrap;
}

.amount-button {
  padding: 8px 16px;
  background: var(--sf-card-bg);
  color: var(--sf-primary-text);
  border: 1px solid var(--sf-border-color);
  border-radius: 12px;
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: 600;
  transition: background 0.3s ease, border-color 0.3s ease;
  min-width: 100px;
  box-sizing: border-box;
}

.amount-button.active {
  background: var(--sf-accent-color);
  border-color: var(--sf-accent-hover);
  color: var(--sf-primary-text);
}

.amount-button:hover:not(.active) {
  background: #3a3a5a;
  border-color: var(--sf-accent-color);
}

.light-mode .amount-button:hover:not(.active) {
  background: rgba(0, 0, 0, 0.05); /* Lighter hover effect in light mode */
}

/* Selected Amount */
.selected-amount {
  background: var(--sf-card-bg);
  padding: 16px;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  margin-bottom: 24px;
  font-size: 1rem;
  width: 100%;
  text-align: center;
}

.light-mode .selected-amount {
  background: rgba(0, 0, 0, 0.05); /* Subtle gray background in light mode */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05); /* Lighter shadow in light mode */
}

.selected-amount p {
  margin: 4px 0;
  color: var(--sf-primary-text);
}

/* Swap Actions */
.swap-actions {
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
  width: 100%;
}

.swap-button {
  padding: 12px 24px;
  background: var(--sf-accent-color);
  color: var(--sf-primary-text);
  border: none;
  border-radius: 12px;
  font-size: 1rem;
  font-weight: 700;
  cursor: pointer;
  transition: background 0.3s ease;
  width: 100%;
  max-width: 200px;
  box-sizing: border-box;
}

.swap-button.disabled {
  background: #4a4a6a;
  cursor: not-allowed;
  color: var(--sf-secondary-text);
}

.light-mode .swap-button.disabled {
  background: #d3d3d3; /* Lighter gray for disabled state in light mode */
  color: var(--sf-secondary-text); /* #666666 in light mode */
}

.swap-button:hover:not(.disabled) {
  background: var(--sf-accent-hover);
}

/* Transaction Status */
.transaction-status {
  margin-top: 24px;
  font-size: 0.9rem;
  color: var(--sf-accent-color);
  text-align: center;
  width: 100%;
  max-width: 400px;
}

/* Responsive Design (≤430px) */
@media (max-width: 430px) {
  .swap-container {
    margin: 16px auto;
    padding: 20px;
    max-width: 100%;
  }

  .swap-header {
    padding: 0;
  }

  .swap-title {
    font-size: 1.5rem;
  }

  .swap-balance-info {
    padding: 12px;
  }

  .token-logo {
    width: 24px;
    height: 24px;
  }

  .token-balance {
    margin-bottom: 8px;
  }

  .token-balance span {
    font-size: 0.9rem;
  }

  .swap-toggle {
    gap: 8px;
  }

  .toggle-button {
    padding: 8px 16px;
    font-size: 0.9rem;
    min-width: 120px;
  }

  .amount-buttons {
    gap: 4px;
  }

  .amount-button {
    padding: 6px 12px;
    font-size: 0.8rem;
    min-width: 90px;
  }

  .selected-amount {
    padding: 12px;
    font-size: 0.9rem;
  }

  .swap-actions {
    margin-bottom: 16px;
  }

  .swap-button {
    padding: 8px 16px;
    font-size: 0.9rem;
    max-width: 180px;
  }

  .transaction-status {
    font-size: 0.8rem;
  }
}