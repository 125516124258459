/* Custom Theme Variables */
:root {
  /* Default (dark mode) */
  --cc-bg-color: #1e1e33;
  --cc-primary-text: #ffffff;
  --cc-secondary-text: #a0a0c0;
  --cc-accent-color: #8a2be2;
  --cc-accent-hover: #b72be2;
  --cc-border-color: rgba(255, 255, 255, 0.1);
  --cc-card-bg: #2d2d4a;
  --cc-shadow: 0 8px 20px rgba(0, 0, 0, 0.5);
  --cc-input-bg: #3a3a5a;
}

/* Light Mode Overrides */
.light-mode {
  --cc-bg-color: #ffffff;
  --cc-primary-text: #333333;
  --cc-secondary-text: #666666;
  --cc-accent-color: #6a1b9a; /* Softer purple for light mode */
  --cc-accent-hover: #8a2be2;
  --cc-border-color: rgba(0, 0, 0, 0.1);
  --cc-card-bg: #f0f0f0;
  --cc-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  --cc-input-bg: #e0e0e0;
}

/* Chat Component Container */
.chat-component {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 320px;
  max-height: 80vh;
  background: var(--cc-card-bg);
  border-radius: 16px;
  box-shadow: var(--cc-shadow);
  z-index: 1000;
  transition: transform 0.3s ease, opacity 0.3s ease, height 0.3s ease;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

/* Minimized State */
.chat-component.minimized {
  width: 80px;
  height: 40px;
  background: linear-gradient(to bottom, var(--cc-accent-color), var(--cc-accent-hover));
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
  border-radius: 20px;
  transition: all 0.3s ease; /* Smooth transition for theme changes */
}

.light-mode .chat-component.minimized {
  background: linear-gradient(to bottom, #d0d0d0, #e0e0e0); /* Gray gradient for light mode */
}

.chat-component.minimized .chat-label {
  font-size: 0.9rem;
  color: var(--cc-primary-text);
  margin: 0;
  font-weight: 500;
}

.light-mode .chat-component.minimized .chat-label {
  color: #ffffff; /* White text for contrast in light mode */
}

/* Open State */
.chat-component.open {
  transform: translateY(0);
  opacity: 1;
  height: auto;
}

/* Chat Header */
.chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  background: var(--cc-bg-color);
  border-bottom: 1px solid var(--cc-border-color);
  flex-shrink: 0; /* Prevent header from shrinking */
}

.chat-title {
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0;
  color: var(--cc-primary-text);
}

.chat-close {
  background: transparent;
  border: none;
  color: var(--cc-primary-text);
  font-size: 1.5rem;
  cursor: pointer;
  transition: color 0.3s ease;
}

.chat-close:hover {
  color: var(--cc-accent-color);
}

/* Chat History */
.chat-history {
  max-height: calc(80vh - 140px); /* Adjust for header and input */
  overflow-y: auto;
  padding: 16px;
  box-sizing: border-box;
  flex-grow: 1; /* Allow history to take available space */
  display: flex;
  flex-direction: column;
  gap: 12px; /* Space between messages */
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: var(--cc-accent-color) var(--cc-bg-color); /* Custom scrollbar */
}

/* Custom Scrollbar for Webkit */
.chat-history::-webkit-scrollbar {
  width: 6px;
}

.chat-history::-webkit-scrollbar-track {
  background: var(--cc-bg-color);
}

.chat-history::-webkit-scrollbar-thumb {
  background: var(--cc-accent-color);
  border-radius: 3px;
}

.chat-history::-webkit-scrollbar-thumb:hover {
  background: var(--cc-accent-hover);
}

/* Chat Message */
.chat-message {
  margin-bottom: 0; /* Controlled by flex gap */
  padding: 10px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  border: 1px solid var(--cc-border-color);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  display: flex;
  flex-direction: column;
  max-width: 80%; /* Prevent messages from stretching too wide */
}

.light-mode .chat-message {
  background: rgba(0, 0, 0, 0.05); /* Subtle gray background in light mode */
}

.chat-message:hover {
  transform: translateX(2px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.chat-message.my-message {
  background: rgba(138, 43, 226, 0.1);
  border-color: var(--cc-accent-color);
  align-self: flex-end; /* Align user's messages to the right */
}

.light-mode .chat-message.my-message {
  background: rgba(106, 27, 154, 0.1); /* Adjusted for light mode accent */
}

.chat-message:not(.my-message) {
  align-self: flex-start; /* Align other messages to the left */
}

.chat-message-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6px;
  font-size: 0.9rem;
}

.chat-sender {
  color: var(--cc-primary-text);
  font-weight: 600;
}

.chat-timestamp {
  color: var(--cc-secondary-text);
  font-size: 0.85rem;
}

.chat-message-content {
  word-wrap: break-word; /* Ensure long words wrap */
  color: var(--cc-primary-text);
  font-size: 1rem;
  line-height: 1.4;
}

/* Message Input */
.message-input-container {
  display: flex;
  padding: 12px 16px;
  background: var(--cc-bg-color);
  border-top: 1px solid var(--cc-border-color);
  box-sizing: border-box;
  flex-shrink: 0; /* Prevent input container from shrinking */
}

.message-input {
  flex: 1;
  padding: 8px 12px;
  border: 1px solid var(--cc-border-color);
  border-radius: 8px;
  background: var(--cc-input-bg);
  color: var(--cc-primary-text);
  font-size: 1rem;
  margin-right: 8px;
  box-sizing: border-box;
}

.message-input:focus {
  outline: none;
  border-color: var(--cc-accent-color);
}

.send-button {
  padding: 8px 16px;
  background: var(--cc-accent-color); /* Use accent color */
  color: var(--cc-primary-text);
  border: none;
  border-radius: 8px;
  font-size: 0.9rem;
  font-weight: 600;
  cursor: pointer;
  transition: background 0.2s ease;
}

.send-button:hover {
  background: var(--cc-accent-hover);
}

/* Mobile Adjustments (≤430px) */
@media (max-width: 430px) {
  .chat-component {
    bottom: 10px;
    right: 10px;
    width: 280px;
    max-height: 70vh;
  }

  .chat-component.minimized {
    width: 60px;
    height: 30px;
  }

  .chat-component.minimized .chat-label {
    font-size: 0.8rem;
  }

  .chat-header {
    padding: 8px 12px;
  }

  .chat-title {
    font-size: 1rem;
  }

  .chat-close {
    font-size: 1.3rem;
  }

  .chat-history {
    max-height: calc(70vh - 120px);
    padding: 12px;
  }

  .chat-message {
    padding: 8px;
  }

  .chat-message-header {
    font-size: 0.85rem;
  }

  .chat-timestamp {
    font-size: 0.8rem;
  }

  .chat-message-content {
    font-size: 0.9rem;
  }

  .message-input-container {
    padding: 8px 12px;
  }

  .message-input {
    padding: 6px 10px;
    font-size: 0.9rem;
  }

  .send-button {
    padding: 6px 12px;
    font-size: 0.85rem;
  }
}