/* Custom Theme Variables */
:root {
  --nd-bg-color: #1e1e33;
  --nd-primary-text: #ffffff;
  --nd-secondary-text: #a0a0c0;
  --nd-accent-color: #8a2be2;
  --nd-accent-hover: #b72be2;
  --nd-border-color: rgba(255, 255, 255, 0.1);
  --nd-card-bg: #2d2d4a;
  --nd-shadow: 0 8px 20px rgba(0, 0, 0, 0.5);
}

/* Light Mode Overrides */
.light-mode {
  --nd-bg-color: #ffffff;
  --nd-primary-text: #333333;
  --nd-secondary-text: #666666;
  --nd-accent-color: #6a1b9a; /* Softer purple for light mode */
  --nd-accent-hover: #8a2be2;
  --nd-border-color: rgba(0, 0, 0, 0.1);
  --nd-card-bg: #f0f0f0;
  --nd-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
}

/* Container */
.nft-details-container {
  width: 90%;
  max-width: 900px;
  margin: 40px auto;
  padding: 32px;
  background: linear-gradient(135deg, var(--nd-bg-color), var(--nd-card-bg));
  border-radius: 20px;
  box-shadow: var(--nd-shadow);
  color: var(--nd-primary-text);
  font-family: 'Inter', Arial, sans-serif;
  box-sizing: border-box;
}

/* Back Button */
.back-button {
  background: transparent;
  border: none;
  font-size: 1.25rem;
  color: var(--nd-secondary-text);
  cursor: pointer;
  margin-bottom: 24px;
  padding: 8px 16px;
  transition: color 0.3s ease;
}

.back-button:hover {
  color: var(--nd-accent-color);
}

/* NFT Overview */
.nft-overview {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  margin-bottom: 32px;
}

.nft-image-container {
  flex: 1 1 300px;
  min-width: 0;
}

.nft-image {
  width: 100%;
  border-radius: 12px;
  object-fit: cover;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.light-mode .nft-image {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* Lighter shadow in light mode */
}

.nft-info {
  flex: 1 1 300px;
  min-width: 0;
}

.nft-title {
  font-size: 2rem;
  font-weight: 700;
  margin: 0 0 16px;
  color: var(--nd-primary-text);
}

.nft-description {
  background: var(--nd-card-bg);
  padding: 16px;
  border-radius: 12px;
  margin-bottom: 24px;
  font-size: 1rem;
  color: var(--nd-secondary-text);
}

.nft-traits {
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 12px;
}

.nft-trait {
  background: rgba(255, 255, 255, 0.05);
  padding: 10px;
  border-radius: 8px;
  border: 1px solid var(--nd-border-color);
  font-size: 0.9rem;
}

.light-mode .nft-trait {
  background: rgba(0, 0, 0, 0.05); /* Subtle gray background in light mode */
}

.nft-trait strong {
  color: var(--nd-primary-text);
  font-weight: 600;
}

/* NFT Actions */
.nft-actions {
  text-align: center;
  margin: 32px 0;
  padding: 0 16px;
}

.latest-price {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--nd-accent-color);
  margin-bottom: 16px;
}

.action-button {
  padding: 12px 24px;
  background: var(--nd-card-bg); /* Use card background instead of flat dark */
  color: var(--nd-primary-text);
  border: 1px solid var(--nd-accent-color);
  border-radius: 12px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background 0.2s ease, border-color 0.2s ease;
  margin: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.action-button:hover:not(.disabled) {
  background: var(--nd-card-bg); /* Keep background consistent */
  border-color: var(--nd-accent-hover);
}

.action-button.buy { background: var(--nd-card-bg); }
.action-button.buy:hover { border-color: var(--nd-accent-hover); }
.action-button.sell { background: var(--nd-card-bg); }
.action-button.sell:hover { border-color: var(--nd-accent-hover); }
.action-button.cancel { background: var(--nd-card-bg); }
.action-button.cancel:hover { border-color: var(--nd-accent-hover); }
.action-button.claim { background: var(--nd-card-bg); }
.action-button.claim:hover { border-color: var(--nd-accent-hover); }
.action-button.disabled {
  background: var(--nd-card-bg);
  border-color: var(--nd-border-color);
  cursor: not-allowed;
  color: var(--nd-secondary-text);
}

.light-mode .action-button {
  background: var(--nd-card-bg); /* #f0f0f0 in light mode */
  color: var(--nd-primary-text); /* #333 in light mode */
}

.light-mode .action-button:hover:not(.disabled) {
  background: var(--nd-card-bg); /* Keep background */
  border-color: var(--nd-accent-hover); /* #8a2be2 in light mode */
}

.light-mode .action-button.disabled {
  background: var(--nd-card-bg); /* #f0f0f0 */
  color: var(--nd-secondary-text); /* #666 in light mode */
}

.expired-text {
  font-size: 1.2rem;
  color: var(--nd-secondary-text);
  font-style: italic;
}

.connect-wallet {
  font-size: 1.1rem;
  color: var(--nd-secondary-text);
  background: var(--nd-card-bg);
  padding: 12px 20px;
  border-radius: 12px;
  margin-top: 16px;
}

/* NFT History */
.nft-history {
  background: var(--nd-card-bg);
  padding: 24px;
  border-radius: 16px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  max-height: 400px;
  overflow-y: auto;
}

.light-mode .nft-history {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* Lighter shadow in light mode */
}

.nft-history h2 {
  font-size: 1.5rem;
  margin: 0 0 16px;
  color: var(--nd-primary-text);
  border-bottom: 1px solid var(--nd-border-color);
  padding-bottom: 12px;
}

.history-list {
  padding: 0;
}

.history-item {
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--nd-border-color);
}

.history-date {
  font-size: 0.9rem;
  color: var(--nd-secondary-text);
  margin-bottom: 8px;
}

.history-actions {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.history-action {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 1rem;
  color: var(--nd-primary-text);
}

.history-icon {
  color: var(--nd-accent-color);
  font-size: 1.2rem;
}

/* Confirmation Modal */
.confirmation-modal {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.2s ease-in;
}

.light-mode .confirmation-modal {
  background: rgba(0, 0, 0, 0.5); /* Lighter overlay in light mode */
}

.modal-content {
  background: var(--nd-card-bg);
  padding: 24px;
  border-radius: 16px;
  border: 1px solid var(--nd-border-color);
  width: 90%;
  max-width: 400px;
  box-shadow: var(--nd-shadow);
  display: flex;
  flex-direction: column;
  animation: slideIn 0.3s ease-out;
  color: var(--nd-primary-text);
  box-sizing: border-box;
}

.light-mode .modal-content {
  box-shadow: var(--nd-shadow); /* Adjusted to light mode shadow */
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  padding-bottom: 12px;
  border-bottom: 1px solid var(--nd-border-color);
}

.modal-header h2 {
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0;
  color: var(--nd-primary-text);
}

.modal-close {
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: var(--nd-primary-text);
  transition: color 0.3s ease;
}

.modal-close:hover {
  color: var(--nd-accent-color);
}

.modal-body {
  margin-bottom: 24px;
  font-size: 1rem;
  color: var(--nd-secondary-text);
}

.price-input,
.date-input {
  width: 100%;
  padding: 10px;
  border: 1px solid var(--nd-border-color);
  border-radius: 12px;
  background: var(--nd-card-bg); /* Use card background */
  color: var(--nd-primary-text);
  font-size: 1rem;
  box-sizing: border-box;
  margin: 8px 0;
}

.price-input:focus,
.date-input:focus {
  outline: none;
  border-color: var(--nd-accent-color);
}

.confirmed-price {
  font-weight: 600;
  font-size: 1.25rem;
  color: var(--nd-accent-color);
  margin: 8px 0;
}

.modal-actions {
  display: flex;
  gap: 12px;
  justify-content: center;
}

.action-button {
  padding: 12px 24px;
  background: var(--nd-card-bg);
  color: var(--nd-primary-text);
  border: 1px solid var(--nd-accent-color);
  border-radius: 12px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background 0.2s ease, border-color 0.2s ease;
}

.action-button:hover:not(.disabled) {
  background: var(--nd-card-bg);
  border-color: var(--nd-accent-hover);
}

.action-button.confirm { background: var(--nd-card-bg); }
.action-button.confirm:hover { border-color: var(--nd-accent-hover); }
.action-button.cancel { background: var(--nd-card-bg); }
.action-button.cancel:hover { border-color: var(--nd-accent-hover); }

.transaction-status {
  margin-top: 16px;
  font-size: 0.9rem;
  color: var(--nd-accent-color);
  text-align: center;
}

/* Loading State */
.loading {
  text-align: center;
  padding: 40px;
  font-size: 1.2rem;
  color: var(--nd-primary-text);
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideIn {
  from { opacity: 0; transform: translateY(-10px); }
  to { opacity: 1; transform: translateY(0); }
}

/* Responsive Design (≤430px) */
@media (max-width: 430px) {
  .nft-details-container {
    margin: 16px auto;
    padding: 20px;
  }

  .back-button {
    font-size: 1rem;
    padding: 6px 12px;
    margin-bottom: 16px;
  }

  .nft-overview {
    flex-direction: column;
    gap: 16px;
  }

  .nft-image {
    border-radius: 8px;
  }

  .nft-title {
    font-size: 1.5rem;
  }

  .nft-description {
    font-size: 0.9rem;
    padding: 12px;
  }

  .nft-trait {
    font-size: 0.8rem;
    padding: 8px;
  }

  .nft-actions {
    margin: 24px 0;
  }

  .latest-price {
    font-size: 1.2rem;
  }

  .action-button {
    padding: 8px 16px;
    font-size: 0.9rem;
    margin: 4px;
  }

  .expired-text {
    font-size: 1rem;
  }

  .connect-wallet {
    font-size: 0.9rem;
    padding: 8px 16px;
  }

  .nft-history {
    padding: 16px;
    max-height: 300px;
  }

  .nft-history h2 {
    font-size: 1.25rem;
  }

  .history-date {
    font-size: 0.8rem;
  }

  .history-action {
    font-size: 0.9rem;
  }

  .history-icon {
    font-size: 1rem;
  }

  .modal-content {
    padding: 16px;
    max-width: 320px;
  }

  .modal-header h2 {
    font-size: 1.2rem;
  }

  .modal-close {
    font-size: 1.3rem;
  }

  .modal-body {
    font-size: 0.9rem;
  }

  .price-input,
  .date-input {
    padding: 8px;
    font-size: 0.9rem;
  }

  .confirmed-price {
    font-size: 1rem;
  }
}