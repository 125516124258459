/* src/styles/Footer.css */

/* Custom Theme Variables */
:root {
  /* Default (dark mode) */
  --f-bg-color: #1e1e33;
  --f-primary-text: #ffffff;
  --f-secondary-text: #a0a0c0;
  --f-accent-color: #8a2be2;
  --f-accent-hover: #b72be2;
  --f-border-color: rgba(255, 255, 255, 0.1);
  --f-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
}

.light-mode {
  --f-bg-color: #ffffff;
  --f-primary-text: #333333;
  --f-secondary-text: #666666;
  --f-accent-color: #6a1b9a; /* Softer purple for light mode */
  --f-accent-hover: #8a2be2;
  --f-border-color: rgba(0, 0, 0, 0.1);
  --f-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

/* Container */
.footer {
  background: var(--f-bg-color);
  color: var(--f-primary-text);
  padding: 20px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
  border-top: 1px solid var(--f-border-color);
  box-shadow: var(--f-shadow);
  transition: all 0.3s ease;
}

/* Fee Toggle */
.fee-toggle {
  display: flex;
  align-items: center;
  gap: 12px;
}

.fee-label {
  margin-right: 0;
  color: var(--f-primary-text);
  font-size: 0.9rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 8px;
}

.fee-label input[type='checkbox'] {
  accent-color: var(--f-accent-color);
  width: 18px;
  height: 18px;
  cursor: pointer;
  transition: accent-color 0.3s ease;
}

.fee-label input[type='checkbox']:hover {
  accent-color: var(--f-accent-hover);
}

.kda-select {
  background: var(--f-bg-color);
  color: var(--f-primary-text);
  border: 1px solid var(--f-border-color);
  padding: 8px 12px;
  border-radius: 12px;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.kda-select:focus {
  border-color: var(--f-accent-color);
  box-shadow: 0 0 6px rgba(138, 43, 226, 0.4);
  outline: none;
}

.kda-select option {
  background: var(--f-bg-color);
  color: var(--f-primary-text);
}

/* Theme Switch Toggle */
.theme-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.theme-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #2d2d4a; /* Dark mode background */
  transition: background 0.4s ease;
  border-radius: 34px;
  border: 1px solid var(--f-border-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px;
  box-sizing: border-box;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.2);
}

.light-mode .slider {
  background: #e0e0e0; /* Light mode background */
}

.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 3px;
  background: var(--f-accent-color);
  transition: transform 0.4s ease, background 0.4s ease;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.theme-switch input:checked + .slider:before {
  transform: translateX(24px);
  background: var(--f-accent-hover);
}

/* Icons */
.slider .icon {
  font-size: 16px;
  transition: color 0.4s ease;
}

/* Dark Mode (Moon Icon) */
.slider .moon {
  color: #a0a0c0; /* Dimmed when not selected (light mode) */
}

.theme-switch input:not(:checked) + .slider .moon {
  color: #ffffff; /* Bright when selected (dark mode) */
}

/* Light Mode (Sun Icon) */
.slider .sun {
  color: #a0a0c0; /* Dimmed when not selected (dark mode) */
}

.theme-switch input:checked + .slider .sun {
  color: #ffcd00; /* Bright yellow when selected (light mode) */
}

/* Copyright Text */
.copyright {
  margin: 0;
  font-size: 0.9rem;
  color: var(--f-secondary-text);
}

/* Responsive Design (≤430px) */
@media (max-width: 430px) {
  .footer {
    padding: 16px 12px;
    flex-direction: column;
    text-align: center;
    gap: 16px;
  }

  .fee-toggle {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }

  .fee-label {
    font-size: 0.8rem;
  }

  .kda-select {
    padding: 6px 10px;
    font-size: 0.8rem;
    width: 100%;
  }

  .theme-switch {
    width: 50px;
    height: 28px;
  }

  .slider:before {
    height: 20px;
    width: 20px;
    bottom: 3px;
  }

  .theme-switch input:checked + .slider:before {
    transform: translateX(20px);
  }

  .slider .icon {
    font-size: 14px;
  }

  .copyright {
    font-size: 0.8rem;
  }
}