/* Custom Theme Variables */
:root {
  /* Default (dark mode) */
  --sw-bg-color: #1e1e33;
  --sw-primary-text: #ffffff;
  --sw-secondary-text: #a0a0c0;
  --sw-accent-color: #8a2be2;
  --sw-accent-hover: #b72be2;
  --sw-border-color: rgba(255, 255, 255, 0.1);
  --sw-card-bg: #2d2d4a;
  --sw-shadow: 0 8px 20px rgba(0, 0, 0, 0.5);
}

.light-mode {
  /* Light mode overrides */
  --sw-bg-color: #ffffff;
  --sw-primary-text: #333333;
  --sw-secondary-text: #666666;
  --sw-accent-color: #6a1b9a; /* Softer purple for light mode */
  --sw-accent-hover: #8a2be2;
  --sw-border-color: rgba(0, 0, 0, 0.1);
  --sw-card-bg: #f0f0f0;
  --sw-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
}

/* Container */
.token-swap-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  color: var(--sw-primary-text);
  padding: 20px;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  background: var(--sw-bg-color); /* Added to adapt background to theme */
  transition: all 0.3s ease; /* Smooth transition for theme changes */
}

/* Background */
.token-swap-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url('../assets/background.png') no-repeat center center;
  background-size: cover;
  opacity: 0.2;
  z-index: 1;
}

.light-mode .token-swap-container::before {
  opacity: 0.1; /* Slightly lighter overlay in light mode for better visibility */
}

/* Disclaimer */
.disclaimer-swap {
  margin-top: 20px;
  font-size: 0.9rem;
  color: var(--sw-secondary-text);
  text-align: center;
  max-width: 80%;
  z-index: 2;
}

/* Token Selector */
.token-selector {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 24px;
  padding: 16px;
  background: var(--sw-card-bg);
  border-radius: 12px;
  border: 1px solid var(--sw-border-color);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 3;
}

.light-mode .token-selector {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05); /* Lighter shadow in light mode */
}

/* React-Select Container Override */
.token-swap-container .css-b62m3t-container {
  position: relative;
  box-sizing: border-box;
  background: var(--sw-card-bg); /* Adapt background to theme */
  border: 1px solid var(--sw-border-color); /* Match border style */
  border-radius: 12px; /* Match token-selector */
  padding: 8px; /* Match customStyles control padding */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5); /* Match customStyles control shadow */
  transition: all 0.3s ease; /* Smooth transition */
}

.light-mode .token-swap-container .css-b62m3t-container {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05); /* Lighter shadow in light mode */
  border-color: var(--sw-border-color); /* Ensure border adapts */
}

.choose-token-logo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

/* KAKA-FLUSH Selector */
.kaka-flush-selector {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 16px;
  background: var(--sw-card-bg);
  border-radius: 12px;
  border: 1px solid var(--sw-border-color);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  margin-top: 24px;
  transition: background 0.3s ease, box-shadow 0.3s ease;
  z-index: 2;
}

.light-mode .kaka-flush-selector {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05); /* Lighter shadow in light mode */
}

.kaka-flush-selector.clickable:hover {
  background: rgba(255, 255, 255, 0.1); /* Dark mode hover effect */
}

.light-mode .kaka-flush-selector.clickable:hover {
  background: rgba(0, 0, 0, 0.1); /* Lighter hover effect in light mode */
}

.kaka-flush-selector.clickable:hover {
  box-shadow: 0 4px 12px rgba(138, 43, 226, 0.2); /* Dark mode hover shadow */
}

.light-mode .kaka-flush-selector.clickable:hover {
  box-shadow: 0 4px 12px rgba(106, 27, 154, 0.2); /* Light mode hover shadow with accent color */
}

.token-label {
  font-size: 1rem;
  color: var(--sw-primary-text);
  font-weight: 600;
}

.arrow {
  font-size: 1.2rem;
  color: var(--sw-accent-color);
}

/* Flush Text */
.flush-your-kaka {
  font-size: 1rem;
  color: var(--sw-secondary-text);
  text-align: center;
  margin-top: 12px;
  z-index: 2;
}

/* Responsive Design (≤430px) */
@media (max-width: 430px) {
  .token-swap-container {
    padding: 16px;
    height: auto;
    min-height: 100vh;
  }
  .disclaimer-swap,
  .flush-your-kaka {
    font-size: 0.8rem;
  }
  .token-selector,
  .kaka-flush-selector {
    padding: 12px;
    gap: 8px;
  }
  .choose-token-logo {
    width: 30px;
    height: 30px;
  }
  .token-label {
    font-size: 0.9rem;
  }
  .arrow {
    font-size: 1rem;
  }
  .token-swap-container .css-b62m3t-container {
    padding: 6px; /* Reduce padding on mobile */
    border-radius: 8px; /* Adjust radius to match */
  }
}