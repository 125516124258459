/* Global Base */
body,
html {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  background-color: var(--c-bg-color, #121212); /* Use variable with fallback */
  color: var(--c-primary-text, #e0e0e0); /* Use variable with fallback */
  transition: all 0.3s ease; /* Smooth transition for theme changes */
}

/* Custom Theme Variables */
:root {
  /* Default (dark mode) */
  --c-bg-color: #0f0f1a;
  --c-primary-text: #e0e0ff;
  --c-secondary-text: #a0a0d0;
  --c-accent-color: #7b00ff;
  --c-accent-hover: #9b3bff;
  --c-border-color: rgba(255, 255, 255, 0.1);
  --c-table-bg: #1a1a2e;
  --c-shadow: 0 8px 24px rgba(0, 0, 0, 0.4);
}

.light-mode {
  /* Light mode overrides */
  --c-bg-color: #ffffff;
  --c-primary-text: #333333;
  --c-secondary-text: #666666;
  --c-accent-color: #6a1b9a; /* Softer purple for light mode */
  --c-accent-hover: #8a2be2;
  --c-border-color: rgba(0, 0, 0, 0.1);
  --c-table-bg: #f5f5f5;
  --c-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
}

/* Collection Header (Hero) */
.collection-header {
  position: relative;
  height: 300px;
  margin-top: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  z-index: 0;
}

.header-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.55);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  z-index: 1;
}

.light-mode .header-overlay {
  background: rgba(0, 0, 0, 0.3); /* Lighter overlay for better visibility in light mode */
}

.collection-logo-title {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.collection-logo {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  margin-right: 20px;
  flex-shrink: 0;
}

.light-mode .collection-logo {
  border: 1px solid var(--c-border-color); /* Ensure logo border is visible in light mode */
}

.collection-title h1 {
  margin: 0;
  font-size: 2.2rem;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.8);
  line-height: 1.2;
  color: var(--c-primary-text); /* Ensure title adapts to theme */
}

.collection-uris {
  display: flex;
  gap: 15px;
  margin-top: 10px;
}

.collection-uris a img {
  width: 36px;
  height: 36px;
}

/* Stats Section */
.collection-stats {
  display: flex;
  justify-content: space-around;
  background: rgba(20, 20, 20, 0.9);
  border-radius: 8px;
  padding: 15px;
  margin: 20px;
  gap: 20px;
}

.light-mode .collection-stats {
  background: rgba(245, 245, 245, 0.9); /* Lighter background in light mode */
}

.stat-item {
  text-align: center;
  flex: 1;
}

.stat-item span:first-child {
  display: block;
  font-weight: 500;
  font-size: 0.9rem;
  color: var(--c-secondary-text);
}

.stat-item span:last-child {
  font-size: 1.1rem;
  font-weight: 700;
  color: var(--c-primary-text);
}

/* Navigation Tabs */
.tab-navigation {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid var(--c-border-color);
  padding: 10px 20px;
  align-items: center;
  background: var(--c-table-bg); /* Ensure background adapts to theme */
}

.tab-item,
.sub-tab-item {
  background: transparent;
  border: none;
  margin-right: 20px;
  padding: 8px 12px;
  font-size: 1rem;
  cursor: pointer;
  color: var(--c-secondary-text);
  transition: color 0.3s, border-bottom 0.3s;
}

.tab-item.active,
.sub-tab-item.active {
  color: var(--c-primary-text);
  border-bottom: 2px solid var(--c-accent-color); /* Use accent color for active state */
}

.tab-item:hover,
.sub-tab-item:hover {
  color: var(--c-accent-color);
}

.sub-tab-navigation {
  display: flex;
  margin-top: 10px;
}

.tab-extras {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 10px;
}

.marketplace-dropdown {
  position: relative;
}

.dropdown-content {
  position: absolute;
  top: 110%;
  left: 0;
  background: var(--c-table-bg);
  min-width: 220px;
  border-radius: 4px;
  box-shadow: var(--c-shadow);
  z-index: 10;
}

.dropdown-item {
  padding: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: var(--c-primary-text); /* Ensure dropdown text adapts */
  transition: background 0.3s;
}

.dropdown-item img {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.dropdown-item:hover {
  background: rgba(255, 255, 255, 0.1);
}

.light-mode .dropdown-item:hover {
  background: rgba(0, 0, 0, 0.1); /* Lighter hover effect in light mode */
}

/* Filters */
.filters {
  background: #1e1e1e;
  border-radius: 8px;
  padding: 15px;
  margin: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.light-mode .filters {
  background: var(--c-table-bg); /* Use table background for consistency */
}

.filter-group {
  display: flex;
  flex-direction: column;
  flex: 1 1 180px;
}

.filter-group label {
  margin-bottom: 5px;
  font-size: 0.9rem;
  color: var(--c-secondary-text);
}

.filter-group select {
  padding: 8px;
  background: var(--c-table-bg);
  border: 1px solid var(--c-border-color);
  border-radius: 4px;
  color: var(--c-primary-text);
}

.checkbox-group label {
  display: flex;
  align-items: center;
}

.checkbox-group input[type="checkbox"] {
  margin-right: 8px;
  accent-color: var(--c-accent-color);
}

/* NFT Cards */
.nft-cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  gap: 20px;
  padding: 20px;
}

.nft-card {
  background: #212121;
  border-radius: 12px;
  overflow: hidden;
  text-decoration: none;
  color: var(--c-primary-text);
  transition: transform 0.2s, box-shadow 0.2s;
  position: relative;
}

.light-mode .nft-card {
  background: var(--c-table-bg); /* Use table background for consistency */
}

.nft-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
}

.nft-card-content {
  padding: 15px;
}

.nft-card h3 {
  margin: 0 0 10px;
  font-size: 1.1rem;
}

.nft-card img {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 10px;
}

.nft-price {
  font-size: 1.1rem;
  font-weight: bold;
  color: var(--c-accent-color);
}

.ribbon {
  position: absolute;
  top: 10px;
  right: -10px;
  background: red; /* Kept red for visibility */
  color: var(--c-primary-text);
  padding: 5px 10px;
  transform: rotate(45deg);
  font-size: 0.8rem;
}

/* Activity Section */
.activity-section,
.listing-section {
  padding: 20px;
}

.activity-item {
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--c-border-color);
  padding: 10px 0;
}

.activity-item-link {
  text-decoration: none;
  color: inherit;
}

.asset-image {
  width: 50px;
  height: 50px;
  border-radius: 6px;
  margin-right: 20px;
}

.activity-item p {
  font-size: 0.95rem;
  color: var(--c-primary-text);
}

.listed-price {
  font-weight: bold;
  color: var(--c-accent-color);
}

.marketplace {
  font-weight: bold;
  color: var(--c-secondary-text);
}

/* Pagination */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  gap: 15px;
}

.pagination button {
  padding: 8px 16px;
  background: linear-gradient(45deg, var(--c-accent-color), var(--c-accent-hover));
  border: none;
  border-radius: 4px;
  color: var(--c-primary-text);
  cursor: pointer;
  transition: opacity 0.3s;
}

.pagination button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.pagination span {
  font-size: 1rem;
  color: var(--c-primary-text);
}

/* No Data Message */
.no-items-message,
.no-data {
  text-align: center;
  font-size: 1.2rem;
  margin-top: 20px;
  color: var(--c-secondary-text);
}

/* Responsive Adjustments */
@media (max-width: 480px) {
  /* Header & Overlay Adjustments */
  .collection-header {
    height: 220px;
    margin-top: 0;
    background-position: center;
  }
  .header-overlay {
    padding: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .collection-logo-title {
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
  }
  .collection-logo {
    width: 70px;
    height: 70px;
    margin: 0 0 10px 0;
  }
  .collection-title h1 {
    font-size: 1.5rem;
    margin: 0;
  }
  .collection-uris {
    justify-content: center;
    margin-top: 10px;
  }
  /* Hide stats on mobile to reduce clutter */
  .collection-stats {
    display: none;
  }
  /* Navigation & Extra Buttons */
  .tab-navigation {
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }
  .tab-extras {
    flex-direction: column;
    margin-left: 0;
    gap: 10px;
    width: 100%;
    margin-top: 10px;
  }
  .tab-item,
  .sub-tab-item {
    font-size: 0.9rem;
    margin-right: 0;
    width: 100%;
    text-align: center;
    margin-bottom: 5px;
  }
  .nft-cards {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    padding: 10px;
  }
}