/* src/styles/Voting.css */

/* Custom Theme Variables */
:root {
  /* Default (dark mode) */
  --v-bg-color: #1e1e33;
  --v-primary-text: #ffffff;
  --v-secondary-text: #a0a0c0;
  --v-accent-color: #8a2be2;
  --v-accent-hover: #b72be2;
  --v-border-color: rgba(255, 255, 255, 0.1);
  --v-card-bg: #2d2d4a;
  --v-shadow: 0 8px 20px rgba(0, 0, 0, 0.5);
}

.light-mode {
  /* Light mode overrides */
  --v-bg-color: #ffffff;
  --v-primary-text: #333333;
  --v-secondary-text: #666666;
  --v-accent-color: #6a1b9a; /* Softer purple for light mode */
  --v-accent-hover: #8a2be2;
  --v-border-color: rgba(0, 0, 0, 0.1);
  --v-card-bg: #f5f5f5;
  --v-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
}

/* Container */
.voting-container {
  width: 90%;
  max-width: 900px;
  margin: 40px auto;
  padding: 32px;
  background: linear-gradient(135deg, var(--v-bg-color), #2a2a4f);
  border-radius: 20px;
  box-shadow: var(--v-shadow);
  color: var(--v-primary-text);
  font-family: 'Inter', Arial, sans-serif;
  box-sizing: border-box;
  transition: all 0.3s ease; /* Smooth transition for theme changes */
}

.light-mode .voting-container {
  background: linear-gradient(135deg, var(--v-bg-color), #f0f0f0); /* Light mode gradient */
}

/* Header */
.voting-header {
  margin-bottom: 32px;
  padding: 0 16px;
  text-align: center;
}

.title {
  font-size: 2.25rem;
  font-weight: 700;
  margin: 0 0 12px;
  color: var(--v-primary-text);
}

.subtitle {
  font-size: 1.1rem;
  color: var(--v-secondary-text);
  margin: 0;
  letter-spacing: 0.5px;
}

/* Proposal Create and Active Proposals */
.proposal-create,
.active-proposals {
  margin-bottom: 32px;
  padding: 24px;
  background: var(--v-card-bg);
  border-radius: 16px;
  border: 1px solid var(--v-border-color);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.proposal-create.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.proposal-create h2,
.active-proposals h2 {
  font-size: 1.6rem;
  font-weight: 600;
  margin: 0 0 16px;
  color: var(--v-primary-text);
}

.rules {
  font-size: 0.95rem;
  color: var(--v-accent-color);
  margin-bottom: 16px;
}

.warning {
  font-size: 0.95rem;
  color: #ff4444; /* Keep warning red for visibility */
  margin-bottom: 16px;
  font-weight: 500;
}

/* Form Groups */
.form-group {
  margin-bottom: 24px;
  width: 100%;
  box-sizing: border-box;
}

.form-group label {
  display: block;
  font-size: 0.95rem;
  color: var(--v-secondary-text);
  font-weight: 600;
  margin-bottom: 8px;
}

.input,
.textarea,
.select {
  width: 100%;
  padding: 12px;
  background: #3a3a5a;
  border: 1px solid var(--v-border-color);
  border-radius: 12px;
  color: var(--v-primary-text);
  font-size: 1rem;
  box-sizing: border-box;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.light-mode .input,
.light-mode .textarea,
.light-mode .select {
  background: #e5e5e5; /* Lighter input background for light mode */
}

.input:focus,
.textarea:focus,
.select:focus {
  border-color: var(--v-accent-color);
  box-shadow: 0 0 6px rgba(138, 43, 226, 0.4);
  outline: none;
}

.textarea {
  height: 120px;
  resize: none;
}

/* Error and Notification */
.error {
  font-size: 0.9rem;
  color: #ff4444; /* Keep error red for visibility */
  margin-top: 6px;
}

.notification {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 12px 24px;
  background: var(--v-accent-color);
  color: var(--v-primary-text);
  font-weight: 600;
  border-radius: 12px;
  box-shadow: var(--v-shadow);
  z-index: 100;
  font-size: 0.95rem;
  animation: slideIn 0.3s ease-out;
}

/* Buttons */
.button {
  padding: 12px 20px;
  background: var(--v-accent-color);
  color: var(--v-primary-text);
  border: none;
  border-radius: 12px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
  box-sizing: border-box;
}

.button:hover:not(:disabled) {
  background: var(--v-accent-hover);
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(138, 43, 226, 0.4);
}

.button:disabled {
  background: #4a4a6a;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
  color: var(--v-secondary-text);
}

.light-mode .button:disabled {
  background: #d0d0d0; /* Lighter disabled button in light mode */
}

.button.primary { background: var(--v-accent-color); }
.button.secondary { background: #4a4a6a; }
.button.secondary:hover { background: #5a5a7a; }
.light-mode .button.secondary { background: #d0d0d0; }
.light-mode .button.secondary:hover { background: #c0c0c0; }
.button.block { display: block; width: 100%; max-width: none; }

/* Proposal Item */
.proposal-item {
  padding: 24px;
  margin-bottom: 24px;
  background: var(--v-card-bg);
  border-radius: 16px;
  border: 1px solid var(--v-border-color);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.3s ease;
}

.proposal-item:hover {
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.4);
}

.proposal-item h3 {
  font-size: 1.3rem;
  font-weight: 600;
  margin: 0 0 12px;
  color: var(--v-primary-text);
}

.proposal-item p {
  font-size: 1rem;
  color: var(--v-secondary-text);
  margin-bottom: 12px;
}

.asset-info,
.end-date {
  font-size: 0.9rem;
  color: var(--v-secondary-text);
  margin-bottom: 12px;
}

.end-date.expired {
  color: #ff4444; /* Keep expired date red for visibility */
  font-weight: 600;
}

.user-vote-message {
  font-size: 0.95rem;
  color: var(--v-accent-color);
  margin-bottom: 12px;
  font-style: italic;
  text-align: center;
}

/* Chart */
.chart-container {
  margin: 16px 0;
}

.chart-bar {
  display: flex;
  height: 30px;
  background: #3a3a5a;
  border-radius: 12px;
  overflow: hidden;
  border: 1px solid var(--v-border-color);
}

.light-mode .chart-bar {
  background: #e5e5e5; /* Lighter chart background in light mode */
}

.chart-yes {
  background: #4caf50; /* Keep green for "Yes" votes */
  color: var(--v-primary-text);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: width 0.5s ease;
}

.chart-no {
  background: #f44336; /* Keep red for "No" votes */
  color: var(--v-primary-text);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: width 0.5s ease;
}

.vote-counts {
  display: flex;
  justify-content: space-between;
  font-size: 0.95rem;
  color: var(--v-secondary-text);
  margin-bottom: 16px;
}

.proposal-buttons {
  display: flex;
  gap: 12px;
  justify-content: center;
  flex-wrap: wrap;
}

/* Loading Overlay */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 200;
}

.light-mode .loading-overlay {
  background: rgba(255, 255, 255, 0.8); /* Light mode overlay */
}

/* Text Center */
.text-center {
  text-align: center;
}

/* Animations */
@keyframes slideIn {
  from { opacity: 0; transform: translateY(-10px); }
  to { opacity: 1; transform: translateY(0); }
}

/* Responsive Design (≤430px) */
@media (max-width: 430px) {
  .voting-container {
    margin: 20px auto;
    padding: 20px;
  }
  .voting-header {
    margin-bottom: 24px;
  }
  .title {
    font-size: 1.75rem;
  }
  .subtitle {
    font-size: 1rem;
  }
  .proposal-create,
  .active-proposals {
    padding: 16px;
  }
  .proposal-create h2,
  .active-proposals h2 {
    font-size: 1.4rem;
  }
  .rules,
  .warning {
    font-size: 0.85rem;
  }
  .form-group {
    margin-bottom: 16px;
  }
  .input,
  .textarea,
  .select {
    padding: 10px;
    font-size: 0.9rem;
  }
  .textarea {
    height: 80px;
  }
  .error {
    font-size: 0.8rem;
  }
  .notification {
    padding: 10px 20px;
    font-size: 0.9rem;
    max-width: 90%;
  }
  .button {
    padding: 10px 16px;
    font-size: 0.9rem;
    max-width: 140px;
  }
  .proposal-item {
    padding: 16px;
    margin-bottom: 20px;
  }
  .proposal-item h3 {
    font-size: 1.1rem;
  }
  .proposal-item p {
    font-size: 0.9rem;
  }
  .asset-info,
  .end-date,
  .user-vote-message {
    font-size: 0.8rem;
  }
  .chart-bar {
    height: 20px;
  }
  .vote-counts {
    font-size: 0.85rem;
  }
  .proposal-buttons {
    flex-direction: column;
    gap: 8px;
  }
  .button {
    width: 100%;
  }
}