/* Custom Theme Variables */
:root {
  /* Default (dark mode) */
  --wt-bg-color: #1e1e33;
  --wt-primary-text: #ffffff;
  --wt-secondary-text: #a0a0c0;
  --wt-accent-color: #8a2be2;
  --wt-accent-hover: #b72be2;
  --wt-border-color: rgba(255, 255, 255, 0.1);
  --wt-card-bg: #2d2d4a;
  --wt-shadow: 0 8px 20px rgba(0, 0, 0, 0.5);
}

.light-mode {
  /* Light mode overrides */
  --wt-bg-color: #ffffff;
  --wt-primary-text: #333333;
  --wt-secondary-text: #666666;
  --wt-accent-color: #6a1b9a; /* Softer purple for light mode */
  --wt-accent-hover: #8a2be2;
  --wt-border-color: rgba(0, 0, 0, 0.1);
  --wt-card-bg: #f0f0f0;
  --wt-shadow: 0 8px 20px rgba(0, 0, 0, 0.1); /* Lighter shadow in light mode */
}

/* Container */
.wallet-tokens-container {
  width: 90%;
  max-width: 1000px;
  margin: 40px auto;
  padding: 32px;
  background: linear-gradient(135deg, var(--wt-bg-color), #2a2a4f);
  border-radius: 20px;
  box-shadow: var(--wt-shadow);
  color: var(--wt-primary-text);
  font-family: 'Inter', Arial, sans-serif;
  transition: all 0.3s ease; /* Smooth transition for theme changes */
}

.light-mode .wallet-tokens-container {
  background: linear-gradient(135deg, var(--wt-bg-color), #e0e0e0); /* Lighter gradient in light mode */
}

/* Header */
.wallet-tokens-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px; /* Reduced margin for mobile alignment */
  padding: 0;
}

.wallet-tokens-title {
  font-size: 2.25rem;
  font-weight: 700;
  margin: 0;
  color: var(--wt-primary-text);
}

.wallet-stats {
  display: flex;
  gap: 12px;
  font-size: 0.95rem;
  color: var(--wt-secondary-text);
}

.wallet-stats span {
  background: rgba(255, 255, 255, 0.05);
  padding: 8px 12px;
  border-radius: 12px;
  border: 1px solid var(--wt-border-color);
}

.light-mode .wallet-stats span {
  background: rgba(0, 0, 0, 0.05); /* Lighter background in light mode */
}

/* Search & Filters */
.wallet-tokens-controls {
  display: flex;
  gap: 16px;
  margin-bottom: 16px; /* Reduced margin for mobile alignment */
  padding: 0;
  align-items: center;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  padding: 8px;
  border: 1px solid var(--wt-border-color);
  justify-content: space-between;
}

.light-mode .wallet-tokens-controls {
  background: rgba(0, 0, 0, 0.05); /* Lighter background in light mode */
}

.wallet-tokens-search {
  flex: 1;
  position: relative;
  max-width: 500px;
}

.wallet-tokens-search input {
  width: 100%;
  padding: 10px 12px 10px 36px;
  border-radius: 12px;
  border: none;
  background: #3a3a5a;
  color: var(--wt-primary-text);
  font-size: 0.95rem;
  transition: border-color 0.3s ease;
}

.light-mode .wallet-tokens-search input {
  background: #e0e0e0; /* Lighter background in light mode */
}

.wallet-tokens-search input:focus {
  border-color: var(--wt-accent-color);
  outline: none;
  box-shadow: 0 0 0 2px rgba(138, 43, 226, 0.3);
}

.light-mode .wallet-tokens-search input:focus {
  box-shadow: 0 0 0 2px rgba(106, 27, 154, 0.3); /* Adjusted for light mode accent */
}

.wallet-tokens-search::before {
  content: '\f002'; /* Font Awesome magnifying glass */
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: var(--wt-secondary-text);
  font-size: 0.9rem;
}

.wallet-tokens-filter {
  padding: 10px 16px;
  background: #3a3a5a;
  border: none;
  border-radius: 12px;
  color: var(--wt-primary-text);
  font-size: 0.95rem;
  min-width: 120px;
  cursor: pointer;
  margin-left: auto;
}

.light-mode .wallet-tokens-filter {
  background: #e0e0e0; /* Lighter background in light mode */
}

/* Claim All Rewards Section */
.wallet-tokens-claim-all-rewards-section {
  background: var(--wt-card-bg);
  padding: 16px; /* Reduced padding for mobile */
  margin-bottom: 16px; /* Reduced margin for mobile */
  border-radius: 12px; /* Smaller radius for mobile */
  border: 1px solid var(--wt-border-color);
}

.light-mode .wallet-tokens-claim-all-rewards-section {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05); /* Lighter shadow in light mode */
}

.wallet-tokens-claim-all-rewards-section h2 {
  font-size: 1.5rem; /* Reduced font size for mobile */
  margin: 0 0 12px; /* Reduced margin */
  color: var(--wt-primary-text);
}

.wallet-tokens-claim-all-rewards-section .wallet-tokens-claim-all-content {
  max-height: 150px; /* Reduced height for mobile */
  overflow-y: auto;
}

.wallet-tokens-claim-all-rewards-section .wallet-tokens-staked-token-rewards {
  margin-bottom: 8px;
  padding: 8px; /* Reduced padding for mobile */
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px; /* Smaller radius for mobile */
  cursor: pointer;
  transition: background 0.3s ease;
}

.light-mode .wallet-tokens-claim-all-rewards-section .wallet-tokens-staked-token-rewards {
  background: rgba(0, 0, 0, 0.05); /* Lighter background in light mode */
}

.wallet-tokens-claim-all-rewards-section .wallet-tokens-staked-token-rewards:hover {
  background: rgba(255, 255, 255, 0.1);
}

.light-mode .wallet-tokens-claim-all-rewards-section .wallet-tokens-staked-token-rewards:hover {
  background: rgba(0, 0, 0, 0.1); /* Lighter hover effect in light mode */
}

.wallet-tokens-claim-all-rewards-section .wallet-tokens-staked-token-header {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.wallet-tokens-claim-all-rewards-section .wallet-tokens-staked-token-logo {
  width: 24px; /* Reduced size for mobile */
  height: 24px;
  object-fit: cover;
  border-radius: 6px; /* Smaller radius for mobile */
  margin-right: 6px;
}

.wallet-tokens-claim-all-rewards-section .wallet-tokens-staked-token-name {
  font-size: 0.9rem; /* Reduced font size for mobile */
  font-weight: 600;
  color: var(--wt-primary-text);
}

.wallet-tokens-claim-all-rewards-section .wallet-tokens-reward-item-list {
  margin-top: 4px;
  padding-left: 30px; /* Adjusted for smaller logo */
}

.wallet-tokens-claim-all-rewards-section .wallet-tokens-reward-item {
  display: flex;
  align-items: center;
  gap: 6px; /* Reduced gap for mobile */
  padding: 4px 0; /* Reduced padding for mobile */
}

.wallet-tokens-claim-all-rewards-section .wallet-tokens-reward-token-logo {
  width: 16px; /* Reduced size for mobile */
  height: 16px;
  border-radius: 4px; /* Smaller radius for mobile */
}

.wallet-tokens-claim-all-rewards-section .wallet-tokens-reward-token-id {
  flex: 1;
  color: var(--wt-secondary-text);
  font-size: 0.8rem; /* Reduced font size for mobile */
}

.wallet-tokens-claim-all-rewards-section .wallet-tokens-reward-amount {
  font-weight: 600;
  color: var(--wt-accent-color);
  font-size: 0.8rem; /* Reduced font size for mobile */
}

.wallet-tokens-claim-all-rewards-section button {
  padding: 6px 12px; /* Reduced padding for mobile */
  background: #4a4a6a;
  color: var(--wt-primary-text); /* Use theme variable for text color */
  border: 1px solid var(--wt-accent-color);
  border-radius: 8px;
  font-size: 0.8rem; /* Reduced font size for mobile */
  font-weight: 600;
  cursor: pointer;
  transition: background 0.2s ease, border-color 0.2s ease;
}

.light-mode .wallet-tokens-claim-all-rewards-section button {
  background: #d0d0d0; /* Lighter background in light mode */
}

.wallet-tokens-claim-all-rewards-section button:hover {
  background: #5a5a7a;
  border-color: var(--wt-accent-hover);
}

.light-mode .wallet-tokens-claim-all-rewards-section button:hover {
  background: #c0c0c0; /* Slightly darker on hover in light mode */
}

.wallet-tokens-claim-all-rewards-section .wallet-tokens-transaction-status {
  margin-top: 6px; /* Reduced margin for mobile */
  font-size: 0.75rem; /* Reduced font size for mobile */
}

.wallet-tokens-claim-all-rewards-section .wallet-tokens-transaction-status.success {
  color: #00cc00;
}

.wallet-tokens-claim-all-rewards-section .wallet-tokens-transaction-status.error {
  color: #ff4444;
}

/* Assets List */
.wallet-tokens-assets-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 16px;
  padding: 0 16px;
}

/* Asset Item (Card) */
.wallet-tokens-asset-item {
  display: flex;
  align-items: center;
  padding: 12px;
  background: var(--wt-card-bg);
  border-radius: 12px;
  border: 1px solid var(--wt-border-color);
  transition: all 0.3s ease;
}

.light-mode .wallet-tokens-asset-item {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05); /* Lighter shadow in light mode */
}

.wallet-tokens-asset-item:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 20px rgba(138, 43, 226, 0.2);
}

.light-mode .wallet-tokens-asset-item:hover {
  box-shadow: 0 8px 20px rgba(106, 27, 154, 0.2); /* Adjusted for light mode accent */
}

.wallet-tokens-asset-logo {
  width: 48px;
  height: 48px;
  object-fit: cover;
  border-radius: 8px;
  margin-right: 8px;
  border: 1px solid var(--wt-border-color);
}

.wallet-tokens-asset-logo.wallet-tokens-placeholder-logo {
  background: #3a3a5a;
}

.light-mode .wallet-tokens-asset-logo.wallet-tokens-placeholder-logo {
  background: #d0d0d0; /* Lighter background in light mode */
}

.wallet-tokens-asset-details {
  flex: 1;
}

.wallet-tokens-asset-name {
  font-size: 1.1rem;
  font-weight: 600;
  color: var(--wt-primary-text);
  margin-bottom: 2px;
}

.wallet-tokens-asset-balance {
  font-size: 0.9rem;
  color: var(--wt-secondary-text);
}

.wallet-tokens-action-button {
  padding: 6px 12px;
  background: #4a4a6a;
  color: var(--wt-primary-text); /* Use theme variable for text color */
  border: 1px solid var(--wt-accent-color);
  border-radius: 8px;
  font-size: 0.85rem;
  font-weight: 600;
  cursor: pointer;
  transition: background 0.2s ease, border-color 0.2s ease;
}

.light-mode .wallet-tokens-action-button {
  background: #d0d0d0; /* Lighter background in light mode */
}

.wallet-tokens-action-button:hover {
  background: #5a5a7a;
  border-color: var(--wt-accent-hover);
}

.light-mode .wallet-tokens-action-button:hover {
  background: #c0c0c0; /* Slightly darker on hover in light mode */
}

/* States */
.wallet-tokens-loading,
.wallet-tokens-error,
.wallet-tokens-empty {
  text-align: center;
  padding: 16px;
  font-size: 1rem;
  color: var(--wt-secondary-text);
}

/* Mobile Adjustments (≤430px) */
@media (max-width: 430px) {
  .wallet-tokens-container {
    margin: 8px auto;
    padding: 12px;
    width: 92%;
  }
  .wallet-tokens-header {
    flex-direction: column;
    gap: 8px;
    padding: 0;
  }
  .wallet-tokens-title {
    font-size: 1.5rem;
  }
  .wallet-stats {
    flex-direction: row;
    gap: 6px;
    flex-wrap: wrap;
    justify-content: center;
  }
  .wallet-stats span {
    padding: 6px 10px;
    font-size: 0.85rem;
    border-radius: 8px;
  }
  .wallet-tokens-controls {
    flex-direction: column;
    gap: 8px;
    padding: 8px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 8px;
    border: 1px solid var(--wt-border-color);
    align-items: stretch;
    width: 100%; /* Ensure full width within container */
    box-sizing: border-box; /* Include padding in width calculation */
  }
  .light-mode .wallet-tokens-controls {
    background: rgba(0, 0, 0, 0.05); /* Ensure consistency in light mode */
  }
  .wallet-tokens-search {
    width: 100%; /* Full width within controls */
    max-width: none;
  }
  .wallet-tokens-search input {
    width: 100%;
    padding: 8px 12px 8px 28px; /* Match filter padding for consistency */
    font-size: 0.9rem;
    border-radius: 8px;
    border: none; /* Ensure no extra border */
    background: #3a3a5a;
    color: var(--wt-primary-text);
    box-sizing: border-box; /* Include padding in width calculation */
    max-width: 100%; /* Prevent overflow */
    overflow: hidden; /* Clip content if it exceeds */
  }
  .light-mode .wallet-tokens-search input {
    background: #e0e0e0; /* Ensure consistency in light mode */
  }
  .wallet-tokens-search::before {
    left: 8px;
    font-size: 0.8rem;
  }
  .wallet-tokens-filter {
    width: 100%;
    padding: 8px 12px; /* Match input padding for consistency */
    font-size: 0.9rem;
    min-width: auto;
    border-radius: 8px;
    border: none;
    background: #3a3a5a;
    color: var(--wt-primary-text);
    box-sizing: border-box; /* Include padding in width calculation */
    max-width: 100%; /* Prevent overflow */
    overflow: hidden; /* Clip content if it exceeds */
  }
  .light-mode .wallet-tokens-filter {
    background: #e0e0e0; /* Ensure consistency in light mode */
  }
  .wallet-tokens-assets-list {
    grid-template-columns: 1fr;
    gap: 8px;
    padding: 0 8px;
  }
  .wallet-tokens-asset-item {
    padding: 8px;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
  }
  .wallet-tokens-asset-logo {
    width: 32px;
    height: 32px;
    margin-right: 0;
  }
  .wallet-tokens-asset-details {
    width: 100%;
  }
  .wallet-tokens-asset-name {
    font-size: 0.9rem;
  }
  .wallet-tokens-asset-balance {
    font-size: 0.75rem;
  }
  .wallet-tokens-action-button {
    width: 100%;
    padding: 4px 10px;
    font-size: 0.75rem;
  }
  .light-mode .wallet-tokens-action-button {
    background: #d0d0d0; /* Ensure consistency in light mode */
  }
  .light-mode .wallet-tokens-action-button:hover {
    background: #c0c0c0; /* Ensure consistency in light mode */
  }
  .wallet-tokens-claim-all-rewards-section {
    padding: 12px;
    margin-bottom: 12px;
  }
  .wallet-tokens-claim-all-rewards-section h2 {
    font-size: 1.25rem;
  }
  .wallet-tokens-claim-all-rewards-section .wallet-tokens-staked-token-logo {
    width: 20px;
    height: 20px;
    margin-right: 6px;
  }
  .wallet-tokens-claim-all-rewards-section .wallet-tokens-staked-token-name {
    font-size: 0.85rem;
  }
  .wallet-tokens-claim-all-rewards-section .wallet-tokens-reward-token-logo {
    width: 16px;
    height: 16px;
  }
  .wallet-tokens-claim-all-rewards-section .wallet-tokens-reward-item {
    font-size: 0.75rem;
  }
  .wallet-tokens-claim-all-rewards-section button {
    padding: 6px 12px;
    font-size: 0.75rem;
  }
  .light-mode .wallet-tokens-claim-all-rewards-section button {
    background: #d0d0d0; /* Ensure consistency in light mode */
  }
  .light-mode .wallet-tokens-claim-all-rewards-section button:hover {
    background: #c0c0c0; /* Ensure consistency in light mode */
  }
}