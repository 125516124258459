/* Global Theme Variables */
:root {
  /* Default (dark mode) */
  --app-bg-color: #000000;
  --app-primary-text: #ffffff;
  --app-secondary-text: #aaaaaa;
  --app-accent-color: #8a2be2;
  --app-accent-hover: #b72be2;
  --app-card-bg: #2a2a2a;
  --app-input-bg: #404040;
  --app-button-bg: #8a2be2;
  --app-button-hover: #b72be2;
  --app-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
}

.light-mode {
  /* Light mode overrides */
  --app-bg-color: #ffffff;
  --app-primary-text: #333333;
  --app-secondary-text: #666666;
  --app-accent-color: #6a1b9a; /* Softer purple for light mode */
  --app-accent-hover: #8a2be2;
  --app-card-bg: #f0f0f0;
  --app-input-bg: #e8e8e8; /* Slightly darker input background for light mode */
  --app-button-bg: #6a1b9a;
  --app-button-hover: #8a2be2;
  --app-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}

/* Global Styles */
body, html {
  height: 100%;
  margin: 0;
  background: var(--app-bg-color); /* Use theme variable */
  font-family: 'Space Mono', monospace;
  color: var(--app-primary-text); /* Default text color */
  transition: all 0.3s ease; /* Smooth transition for theme changes */
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  text-align: center;
  background: url('./assets/background.png') no-repeat center center;
  background-size: cover;
  padding-top: 80px;
  padding-bottom: 20px;
  color: var(--app-primary-text); /* Ensure text adapts to theme */
}

.light-mode .App {
  background: linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), url('./assets/background.png') no-repeat center center;
  background-size: cover;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

h2 {
  color: var(--app-primary-text); /* Use theme variable */
}

p {
  color: var(--app-primary-text); /* Use theme variable */
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: var(--app-primary-text); /* Use theme variable */
}

.light-mode .App-header {
  background-color: var(--app-card-bg); /* Light gray background in light mode */
  color: var(--app-primary-text); /* #333 in light mode */
}

.App-link {
  color: var(--app-accent-color); /* Use theme variable */
}

.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-wrap {
  flex: 1;
}

/* Chat Component */
.chat-component {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: var(--app-card-bg); /* Use theme variable */
  color: var(--app-primary-text); /* Use theme variable */
  padding: 10px;
  border-radius: 8px;
  box-shadow: var(--app-shadow); /* Use theme variable */
  width: 300px;
  height: 400px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box; /* Ensure padding is included in width/height */
  transition: height 0.3s ease;
  overflow: hidden; /* Prevent content from overflowing */
}

.chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.chat-title {
  margin: 0;
  font-size: 16px;
}

.chat-history {
  overflow-y: auto;
  flex-grow: 1;
  margin-bottom: 10px;
}

.chat-message {
  background-color: var(--app-input-bg); /* Use theme variable */
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 4px;
  border-bottom: 1px solid var(--app-secondary-text); /* Use theme variable */
}

.chat-message .chat-sender {
  color: var(--app-secondary-text); /* Use theme variable */
}

.my-message {
  background-color: var(--app-button-bg); /* Use theme variable */
}

.my-message .chat-sender {
  color: #ffffff; /* White for better contrast against purple background */
}

.message-input-container {
  display: flex;
  align-items: center;
  gap: 5px;
  width: 100%;
  box-sizing: border-box;
  max-width: 100%; /* Ensure it doesn't exceed parent width */
}

.chat-component input {
  flex-grow: 1;
  flex-shrink: 1; /* Allow input to shrink if needed */
  padding: 8px;
  border: none;
  border-radius: 4px;
  color: var(--app-primary-text); /* Use theme variable */
  background-color: var(--app-input-bg); /* Use theme variable */
  box-sizing: border-box;
  max-width: calc(100% - 70px); /* Reserve space for the send button (~60px + 5px gap) */
}

.chat-component .send-button {
  background-color: var(--app-button-bg); /* Use theme variable */
  border: none;
  color: #ffffff !important; /* White text for better contrast, with high specificity */
  padding: 8px 12px; /* Reduced padding to fit better */
  text-align: center;
  text-decoration: none;
  font-size: 14px; /* Reduced font size to fit better */
  border-radius: 4px;
  cursor: pointer;
  box-sizing: border-box;
  transition: background-color 0.3s ease;
  flex-shrink: 0; /* Prevent button from shrinking */
  white-space: nowrap; /* Prevent text wrapping */
  width: 60px; /* Fixed width to ensure consistent sizing */
}

.chat-component .send-button:hover {
  background-color: var(--app-button-hover); /* Use theme variable */
}

.chat-component .chat-close {
  background-color: var(--app-button-bg); /* Use theme variable */
  color: #ffffff !important; /* White text for better contrast, with high specificity */
  border: none;
  padding: 5px 10px;
  border-radius: 50%;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.chat-component .chat-close:hover {
  background-color: var(--app-button-hover); /* Use theme variable */
}

.minimized-chat {
  height: 40px;
  width: 80px;
  overflow: hidden;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  cursor: pointer;
  bottom: 5%;
  background: linear-gradient(to bottom, var(--app-button-bg), var(--app-button-hover));
}

.minimized-chat:hover {
  box-shadow: var(--app-shadow); /* Use theme variable */
}

.toggle-chat {
  display: none;
}

.chat-component:not(.minimized-chat) .toggle-chat {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: var(--app-card-bg); /* Use theme variable */
  color: var(--app-primary-text); /* Use theme variable */
  border: none;
  padding: 5px 10px;
  border-radius: 50%;
  cursor: pointer;
  font-size: 14px;
}

.chat-message-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.chat-timestamp {
  color: var(--app-secondary-text); /* Use theme variable */
  font-size: 0.8rem;
}

.chat-label {
  color: var(--app-primary-text); /* Use theme variable */
  margin: 0;
  font-size: 16px;
  user-select: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Responsive Adjustments */
@media (max-width: 430px) {
  .chat-component {
    width: 250px;
    height: 350px;
  }

  .minimized-chat {
    width: 60px;
    height: 30px;
    padding: 0 10px;
  }

  .chat-component input {
    padding: 6px;
    max-width: calc(100% - 60px); /* Adjust for smaller button width on mobile */
  }

  .chat-component .send-button {
    padding: 6px 10px; /* Further reduced padding for mobile */
    font-size: 12px; /* Smaller font size for mobile */
    width: 50px; /* Adjusted width for mobile */
  }

  .chat-component .chat-close {
    padding: 4px 8px;
    font-size: 12px;
  }
}