/* src/styles/HomePage.css (or wherever your HomePage CSS is located) */

/* Theme Variables (Consistent with Rewards Page) */
:root {
  /* Default (dark mode) */
  --c-bg-color: #0f0f1a;
  --c-primary-text: #e0e0ff;
  --c-secondary-text: #a0a0d0;
  --c-accent-color: #7b00ff;
  --c-accent-hover: #9b3bff;
  --c-border-color: rgba(255, 255, 255, 0.1);
  --c-card-bg: #252540;
  --c-card-gradient: linear-gradient(135deg, #2a2a4f, #252540);
  --c-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
}

.light-mode {
  /* Light mode overrides */
  --c-bg-color: #ffffff;
  --c-primary-text: #333333;
  --c-secondary-text: #666666;
  --c-accent-color: #6a1b9a; /* Softer purple for light mode */
  --c-accent-hover: #8a2be2;
  --c-border-color: rgba(0, 0, 0, 0.1);
  --c-card-bg: #f5f5f5;
  --c-card-gradient: linear-gradient(135deg, #f0f0f0, #ffffff); /* Light gradient for cards */
  --c-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Base Container */
.home-container {
  background: var(--c-bg-color);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  font-family: 'Inter', Arial, sans-serif;
  transition: all 0.3s ease; /* Smooth transition for theme changes */
}

/* Hero Section */
.hero-section {
  width: 100%;
  position: relative;
  padding: 60px 20px;
  text-align: center;
  border-bottom: 1px solid var(--c-border-color);
  overflow: hidden;
  min-height: 300px; /* Ensure enough space for logos */
}

/* Hero Background */
.hero-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--c-card-gradient);
  z-index: 1;
  pointer-events: none; /* Prevent logos from blocking clicks */
}

/* Hero Logos */
.hero-logo {
  position: absolute;
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 15px;
  opacity: 0.25;
  filter: blur(2px); /* Subtle blur for background effect */
  animation: drift 20s infinite ease-in-out;
}

/* Position each logo uniquely */
.hero-logo-0 { top: 10%; left: 5%; animation-delay: 0s; }
.hero-logo-1 { top: 20%; right: 10%; animation-delay: 2s; }
.hero-logo-2 { bottom: 15%; left: 15%; animation-delay: 4s; }
.hero-logo-3 { top: 30%; left: 50%; animation-delay: 6s; }
.hero-logo-4 { bottom: 25%; right: 20%; animation-delay: 8s; }
.hero-logo-5 { top: 50%; left: 25%; animation-delay: 10s; }
.hero-logo-6 { bottom: 10%; right: 35%; animation-delay: 12s; }
.hero-logo-7 { top: 40%; right: 5%; animation-delay: 14s; }
.hero-logo-8 { bottom: 30%; left: 40%; animation-delay: 16s; }
.hero-logo-9 { top: 15%; right: 45%; animation-delay: 18s; }

/* Drift Animation */
@keyframes drift {
  0% { transform: translate(0, 0); }
  25% { transform: translate(20px, -15px); }
  50% { transform: translate(0, 10px); }
  75% { transform: translate(-15px, -5px); }
  100% { transform: translate(0, 0); }
}

/* Hero Content */
.hero-content {
  position: relative;
  z-index: 2;
  background: rgba(15, 15, 26, 0.7); /* Semi-transparent overlay for readability */
  padding: 20px;
  border-radius: 12px;
  display: inline-block;
  transition: background 0.3s ease; /* Smooth transition for overlay */
}

.light-mode .hero-content {
  background: rgba(255, 255, 255, 0.7); /* Light mode overlay */
}

.hero-title {
  font-size: 3rem;
  font-weight: 700;
  color: var(--c-primary-text);
  margin: 0 0 20px;
  line-height: 1.2;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5); /* Enhance readability */
}

.hero-title .highlight {
  background: linear-gradient(90deg, var(--c-accent-color), var(--c-accent-hover));
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.hero-subtitle {
  font-size: 1.2rem;
  color: var(--c-secondary-text);
  margin: 0 0 30px;
}

.hero-cta {
  display: inline-block;
  padding: 12px 24px;
  font-size: 1.1rem;
  font-weight: 600;
  color: var(--c-primary-text);
  background: var(--c-accent-color);
  border-radius: 8px;
  text-decoration: none;
  transition: background 0.3s ease, transform 0.2s ease;
}

.light-mode .hero-cta {
  color: #ffffff; /* White text in light mode */
}

.hero-cta:hover {
  background: var(--c-accent-hover);
  transform: translateY(-2px);
}

/* Content Wrapper */
.content-wrapper {
  width: 90%;
  max-width: 1200px;
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

/* Section Titles */
.section-title {
  font-size: 2rem;
  font-weight: 600;
  color: var(--c-primary-text);
  margin: 0 0 20px;
  text-align: center;
  text-transform: uppercase;
}

/* Top Collections Section */
.top-collections-section {
  padding: 0;
}

.collections-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
}

.collection-card {
  background: var(--c-card-bg);
  border-radius: 12px;
  padding: 15px;
  text-decoration: none;
  color: inherit;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  border: 1px solid var(--c-border-color);
}

.collection-card:hover {
  transform: translateY(-5px);
  box-shadow: var(--c-shadow);
}

.collection-image {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 10px;
}

.collection-details {
  text-align: center;
}

.collection-name {
  font-size: 1.1rem;
  font-weight: 600;
  color: var(--c-primary-text);
  margin: 0 0 5px;
}

.collection-volume {
  font-size: 0.95rem;
  color: var(--c-secondary-text);
  margin: 0;
}

/* Stats Section */
.stats-section {
  padding: 0;
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.stat-card {
  background: var(--c-card-bg);
  border-radius: 12px;
  padding: 20px;
  text-align: center;
  border: 1px solid var(--c-border-color);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.stat-card:hover {
  transform: translateY(-5px);
  box-shadow: var(--c-shadow);
}

.stat-title {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--c-secondary-text);
  margin: 0 0 10px;
}

.stat-value {
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--c-accent-color);
  margin: 0 0 10px;
}

.stat-description {
  font-size: 0.9rem;
  color: var(--c-secondary-text);
  margin: 0;
}

/* Loading Text */
.loading-text {
  font-size: 1rem;
  color: var(--c-secondary-text);
  text-align: center;
  padding: 20px;
}

/* Mobile Adjustments */
@media (max-width: 430px) {
  .hero-section {
    padding: 30px 10px;
    min-height: 250px;
  }

  .hero-logo {
    width: 50px;
    height: 50px;
  }

  .hero-content {
    padding: 15px;
  }

  .hero-title {
    font-size: 1.8rem;
  }

  .hero-subtitle {
    font-size: 1rem;
    margin-bottom: 20px;
  }

  .hero-cta {
    padding: 10px 20px;
    font-size: 1rem;
  }

  .content-wrapper {
    padding: 20px 0;
    width: 95%;
  }

  .section-title {
    font-size: 1.5rem;
    margin-bottom: 15px;
  }

  .collections-grid {
    grid-template-columns: 1fr;
    gap: 15px;
  }

  .collection-card {
    padding: 10px;
  }

  .collection-image {
    height: 120px;
  }

  .collection-name {
    font-size: 1rem;
  }

  .collection-volume {
    font-size: 0.85rem;
  }

  .stats-grid {
    grid-template-columns: 1fr;
    gap: 15px;
  }

  .stat-card {
    padding: 15px;
  }

  .stat-title {
    font-size: 1.1rem;
  }

  .stat-value {
    font-size: 1.3rem;
  }

  .stat-description {
    font-size: 0.85rem;
  }
}

/* Desktop Adjustments */
@media (min-width: 431px) {
  .hero-title {
    font-size: 3.5rem;
  }

  .hero-subtitle {
    font-size: 1.4rem;
  }

  .hero-cta {
    padding: 14px 28px;
    font-size: 1.2rem;
  }
}