/* Custom Theme Variables */
:root {
  /* Default (dark mode) */
  --c-bg-color: #0f0f1a;
  --c-primary-text: #e0e0ff;
  --c-secondary-text: #a0a0d0;
  --c-accent-color: #7b00ff;
  --c-accent-hover: #9b3bff;
  --c-border-color: rgba(255, 255, 255, 0.1);
  --c-table-bg: #1a1a2e;
  --c-shadow: 0 8px 24px rgba(0, 0, 0, 0.4);
}

.light-mode {
  /* Light mode overrides */
  --c-bg-color: #ffffff;
  --c-primary-text: #333333;
  --c-secondary-text: #666666;
  --c-accent-color: #6a1b9a; /* Softer purple for light mode */
  --c-accent-hover: #8a2be2;
  --c-border-color: rgba(0, 0, 0, 0.1);
  --c-table-bg: #f5f5f5;
  --c-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
}

/* Container */
.collections-container {
  background: var(--c-bg-color);
  color: var(--c-primary-text);
  padding: 40px;
  min-height: 100vh;
  font-family: 'Inter', Arial, sans-serif;
  transition: all 0.3s ease; /* Smooth transition for theme changes */
}

/* Controls */
.controls {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.search-input {
  width: 100%;
  max-width: 600px;
  padding: 14px 20px;
  border: 1px solid var(--c-border-color);
  border-radius: 8px;
  background: var(--c-table-bg);
  color: var(--c-primary-text);
  font-size: 1rem;
  transition: all 0.3s ease;
}

.light-mode .search-input {
  background: #ffffff; /* White background for input in light mode */
}

.search-input:focus {
  border-color: var(--c-accent-color);
  outline: none;
  box-shadow: 0 0 12px rgba(123, 0, 255, 0.5);
}

/* Table Wrapper */
.table-wrapper {
  background: var(--c-table-bg);
  border-radius: 12px;
  box-shadow: var(--c-shadow);
  overflow-x: hidden;
}

/* Collections Table */
.collections-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.collections-table th,
.collections-table td {
  padding: 16px 20px;
  text-align: left;
  border-bottom: 1px solid var(--c-border-color);
  white-space: nowrap; /* Prevent text wrapping */
}

.collections-table th {
  background: linear-gradient(90deg, var(--c-table-bg), #252540);
  font-size: 1rem;
  font-weight: 600;
  color: var(--c-secondary-text);
  text-transform: uppercase;
  letter-spacing: 0.5px;
  cursor: pointer;
  transition: color 0.3s ease;
}

.light-mode .collections-table th {
  background: linear-gradient(90deg, var(--c-table-bg), #e0e0e0); /* Lighter gradient for header in light mode */
}

.collections-table th:hover {
  color: var(--c-accent-color);
}

.collections-table td {
  font-size: 0.95rem;
  color: var(--c-primary-text);
}

/* Collection Name Cell */
.collection-name-cell {
  display: flex;
  align-items: center;
  gap: 15px;
}

.collection-logo {
  width: 50px;
  height: 50px;
  border-radius: 8px;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.collection-logo:hover {
  transform: scale(1.1);
}

.collection-info {
  display: flex;
  flex-direction: column;
}

.collection-name {
  text-decoration: none;
  color: var(--c-primary-text);
  font-weight: 600;
  transition: color 0.3s ease;
}

.collection-name:hover {
  color: var(--c-accent-hover);
}

.collection-ticker {
  font-size: 0.85rem;
  color: var(--c-secondary-text);
}

/* Row Hover Effect */
.collections-table tbody tr {
  transition: background 0.2s ease;
}

.collections-table tbody tr:hover {
  background: rgba(255, 255, 255, 0.05);
}

.light-mode .collections-table tbody tr:hover {
  background: rgba(0, 0, 0, 0.05); /* Lighter hover effect in light mode */
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .collections-container {
    padding: 20px;
  }

  .collections-table th,
  .collections-table td {
    padding: 10px 12px;
    font-size: 0.85rem;
  }

  .collection-logo {
    width: 35px;
    height: 35px;
  }

  .collection-name {
    font-size: 0.9rem;
  }

  .collection-ticker {
    font-size: 0.75rem;
  }

  .search-input {
    max-width: 400px;
    padding: 10px 15px;
    font-size: 0.9rem;
  }

  /* Hide Floor Price and Royalties on mobile */
  .desktop-only {
    display: none;
  }
}

@media (max-width: 430px) {
  .collections-container {
    padding: 15px;
  }

  .collections-table th,
  .collections-table td {
    padding: 8px 10px;
    font-size: 0.75rem;
  }

  .collection-logo {
    width: 30px;
    height: 30px;
  }

  .collection-name {
    font-size: 0.85rem;
  }

  .collection-ticker {
    font-size: 0.7rem;
  }

  .search-input {
    max-width: 300px;
    padding: 8px 12px;
    font-size: 0.85rem;
  }

  .collections-table {
    min-width: 0; /* Allow table to shrink */
  }
}