/* Assuming the history tabs are inside a container */
.history-container {
  background: var(--c-bg-color); /* Use theme variable */
  padding: 20px;
}

/* Update the history tab navigation */
.history-tab-navigation {
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
  border-bottom: 1px solid #333; /* Dark mode default */
  flex-wrap: wrap;
  background: var(--c-bg-color); /* Ensure background matches theme */
}

.light-mode .history-tab-navigation {
  border-bottom: 1px solid #ccc; /* Light mode: lighter border */
}

.history-tab-item {
  padding: 10px 15px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  margin-right: 10px;
  font-size: 16px;
  color: #888; /* Dark mode default */
  font-family: 'Space Mono', monospace;
  display: flex;
  align-items: center;
  transition: color 0.3s ease;
}

.light-mode .history-tab-item {
  color: #666; /* Light mode: darker gray for better contrast */
}

.history-tab-item.active {
  color: #ffffff; /* Dark mode default */
  border-bottom: 2px solid #ffffff;
}

.light-mode .history-tab-item.active {
  color: #333; /* Light mode: dark text for contrast */
  border-bottom: 2px solid #6a1b9a; /* Light mode: accent color */
}

.history-tab-item:hover {
  color: #ffffff; /* Dark mode hover */
}

.light-mode .history-tab-item:hover {
  color: #6a1b9a; /* Light mode hover: accent color */
}

.history-tab-item .tab-icon {
  margin-right: 8px;
}

/* History Item (for the content below tabs) */
.history-item {
  background: #f0f0f0; /* Light mode default */
  border-radius: 8px;
  padding: 15px;
  margin: 10px 0;
  display: flex;
  align-items: center;
  gap: 15px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.light-mode .history-item {
  background: #f0f0f0; /* Already suitable for light mode */
}

.history-item.dark-mode {
  background: #252540; /* Dark mode */
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.history-item-image {
  width: 60px;
  height: 60px;
  border-radius: 8px;
  object-fit: cover;
}

.history-item-details {
  flex: 1;
}

.history-item-date {
  font-size: 0.9rem;
  color: #666; /* Light mode default */
}

.history-item-action {
  font-size: 1rem;
  font-weight: 600;
  color: #333; /* Light mode default */
}

.history-item-platform {
  font-size: 0.9rem;
  color: #6a1b9a; /* Accent color for both modes */
}

.light-mode .history-item-date,
.light-mode .history-item-action {
  color: #666; /* Ensure readability in light mode */
  font-weight: 500;
}

.history-item.dark-mode .history-item-date,
.history-item.dark-mode .history-item-action {
  color: #a0a0d0; /* Dark mode: lighter text */
}

.history-item.dark-mode .history-item-action {
  color: #e0e0ff; /* Dark mode: primary text for emphasis */
}

@media (max-width: 430px) {
  .history-tab-navigation {
    justify-content: flex-start;
    padding-bottom: 10px;
  }

  .history-tab-item {
    font-size: 11px;
    padding: 8px 10px;
    margin-right: 5px;
  }

  .history-tab-item .tab-icon {
    margin-right: 5px;
  }

  .history-item {
    padding: 10px;
    gap: 10px;
  }

  .history-item-image {
    width: 50px;
    height: 50px;
  }

  .history-item-date,
  .history-item-action,
  .history-item-platform {
    font-size: 0.85rem;
  }
}