:root {
  /* Default (dark mode) */
  --wp-bg-color: #0f0f1a;
  --wp-primary-text: #e0e0ff;
  --wp-secondary-text: #a0a0d0;
  --wp-accent-color: #7b00ff;
  --wp-accent-hover: #9b3bff;
  --wp-border-color: rgba(255, 255, 255, 0.1);
  --wp-card-bg: #252540;
  --wp-card-gradient: linear-gradient(135deg, #2a2a4f, #252540);
  --wp-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  --wp-success: #00cc00;
  --wp-warning: #ffcc00;
  --wp-progress-bar: #4a90e2;
}

.light-mode {
  /* Light mode overrides */
  --wp-bg-color: #ffffff;
  --wp-primary-text: #333333;
  --wp-secondary-text: #666666;
  --wp-accent-color: #6a1b9a; /* Softer purple for light mode */
  --wp-accent-hover: #8a2be2;
  --wp-border-color: rgba(0, 0, 0, 0.1);
  --wp-card-bg: #f0f0f0;
  --wp-card-gradient: linear-gradient(135deg, #e0e0e0, #f0f0f0); /* Lighter gradient for light mode */
  --wp-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  --wp-success: #00cc00; /* Keep semantic colors consistent */
  --wp-warning: #ffcc00;
  --wp-progress-bar: #4a90e2;
}

/* Reset and Base Styles */
.rewards-rewards-container {
  width: 90%;
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
  background: var(--wp-bg-color);
  border-radius: 16px;
  box-shadow: var(--wp-shadow);
  color: var(--wp-primary-text);
  font-family: 'Inter', Arial, sans-serif;
  display: flex;
  flex-direction: column;
  gap: 25px;
  transition: all 0.3s ease; /* Smooth transition for theme changes */
}

/* Header Styling */
.rewards-rewards-header {
  margin-bottom: 20px;
  padding: 0;
}

.rewards-rewards-title {
  font-size: 2.5rem;
  font-weight: 700;
  margin: 0;
  color: var(--wp-primary-text); /* Use solid color for better visibility */
  text-align: center;
}

.light-mode .rewards-rewards-title {
  background: linear-gradient(90deg, var(--wp-accent-color), var(--wp-accent-hover));
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent; /* Gradient text in light mode for better contrast */
}

/* Content Styling */
.rewards-rewards-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 10px;
}

/* Error and Loading Cards */
.rewards-error-card,
.rewards-loading-card {
  background: var(--wp-card-bg);
  padding: 15px;
  border-radius: 8px;
  text-align: center;
  font-size: 1rem;
  color: var(--wp-secondary-text);
  margin: 10px 0;
}

/* Wallet Info */
.rewards-wallet-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--wp-card-bg);
  padding: 12px 15px;
  border-radius: 8px;
  margin: 10px 0;
  box-sizing: border-box;
}

.rewards-wallet-info span {
  font-size: 1rem;
  color: var(--wp-primary-text);
  word-break: break-all;
}

.rewards-copy-button {
  background: none;
  border: none;
  cursor: pointer;
  color: var(--wp-accent-color);
  font-size: 1.2rem;
  padding: 5px;
  transition: color 0.3s ease;
}

.rewards-copy-button:hover {
  color: var(--wp-accent-hover);
}

/* Section Styling */
.rewards-rewards-section,
.rewards-buckets-section,
.rewards-validators-section,
.rewards-recommendations-section {
  background: var(--wp-card-gradient);
  padding: 20px;
  border-radius: 12px;
  border: 1px solid var(--wp-border-color);
  box-shadow: var(--wp-shadow);
  transition: transform 0.2s ease, background 0.3s ease;
}

.rewards-rewards-section:hover,
.rewards-buckets-section:hover,
.rewards-validators-section:hover,
.rewards-recommendations-section:hover {
  transform: translateY(-3px);
}

.light-mode .rewards-rewards-section:hover,
.light-mode .rewards-buckets-section:hover,
.light-mode .rewards-validators-section:hover,
.light-mode .rewards-recommendations-section:hover {
  box-shadow: 0 6px 15px rgba(106, 27, 154, 0.2); /* Light mode hover shadow with accent color */
}

/* Card Header */
.rewards-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: color 0.3s ease;
}

.rewards-card-header:hover {
  color: var(--wp-accent-hover);
}

.rewards-section-title {
  font-size: 1.5rem;
  margin: 0;
  color: var(--wp-primary-text);
  text-transform: uppercase;
  letter-spacing: 1px;
}

/* Reward Card */
.rewards-reward-card,
.rewards-bucket-card,
.rewards-validator-card,
.rewards-recommendation-card {
  background: var(--wp-card-gradient);
  padding: 10px;
  border-radius: 8px;
  border: 1px solid var(--wp-border-color);
  margin-bottom: 10px;
  transition: transform 0.2s, box-shadow 0.2s;
}

.light-mode .rewards-reward-card,
.light-mode .rewards-bucket-card,
.light-mode .rewards-validator-card,
.light-mode .rewards-recommendation-card {
  background: var(--wp-card-gradient); /* Use lighter gradient in light mode */
}

.rewards-reward-card:hover,
.rewards-bucket-card:hover,
.rewards-validator-card:hover,
.rewards-recommendation-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 15px rgba(123, 0, 255, 0.3);
}

.light-mode .rewards-reward-card:hover,
.light-mode .rewards-bucket-card:hover,
.light-mode .rewards-validator-card:hover,
.light-mode .rewards-recommendation-card:hover {
  box-shadow: 0 6px 15px rgba(106, 27, 154, 0.2); /* Light mode hover shadow with accent color */
}

/* Reward Row */
.rewards-reward-row {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  padding: 6px 10px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 6px;
}

.light-mode .rewards-reward-row {
  background: rgba(0, 0, 0, 0.05); /* Lighter background in light mode */
}

.rewards-reward-label {
  font-weight: 600;
  color: var(--wp-primary-text);
  font-size: 0.95rem;
  min-width: 120px;
  text-align: left;
  cursor: pointer;
}

.rewards-reward-value {
  color: var(--wp-secondary-text);
  font-size: 0.95rem;
  margin-right: 10px;
  flex: 1;
}

.rewards-progress-bar {
  width: 100%;
  height: 8px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  overflow: hidden;
}

.light-mode .rewards-progress-bar {
  background: rgba(0, 0, 0, 0.1); /* Lighter background in light mode */
}

.rewards-progress {
  height: 100%;
  background: var(--wp-progress-bar);
  transition: width 0.3s ease;
}

.rewards-epoch-details {
  margin-top: 5px;
  padding: 8px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 4px;
  color: var(--wp-secondary-text);
  font-size: 0.85rem;
}

.light-mode .rewards-epoch-details {
  background: rgba(0, 0, 0, 0.05); /* Lighter background in light mode */
}

/* Bucket Card */
.rewards-bucket-header {
  display: flex;
  align-items: center;
  gap: 10px;
}

.rewards-bucket-header input[type="checkbox"] {
  margin: 0;
  width: 18px;
  height: 18px;
  accent-color: var(--wp-accent-color); /* Use theme accent color */
}

.rewards-card-id {
  margin-right: 10px;
}

.rewards-expand-icon {
  transition: transform 0.2s;
}

.rewards-info-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  padding: 6px 10px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 4px;
}

.light-mode .rewards-info-row {
  background: rgba(0, 0, 0, 0.05); /* Lighter background in light mode */
}

.rewards-info-label {
  font-weight: 600;
  color: var(--wp-primary-text);
  font-size: 0.95rem;
  min-width: 130px;
  text-align: left;
}

.rewards-info-value {
  color: var(--wp-secondary-text);
  font-size: 0.95rem;
  text-align: right;
  flex: 1;
}

.rewards-buckets-stack,
.rewards-validators-stack {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.rewards-bucket-filter {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.rewards-bucket-filter label,
.rewards-sort-options label,
.rewards-filter-options label {
  font-size: 1rem;
  color: var(--wp-primary-text);
}

.rewards-bucket-filter select,
.rewards-sort-options select,
.rewards-filter-options select,
.rewards-select {
  padding: 8px 12px;
  border: 1px solid var(--wp-border-color);
  border-radius: 6px;
  background: var(--wp-card-bg);
  color: var(--wp-primary-text); /* Ensure text is readable in light mode */
  font-size: 0.95rem;
  cursor: pointer;
  transition: border-color 0.3s ease;
  box-sizing: border-box;
}

.rewards-bucket-filter select:focus,
.rewards-sort-options select:focus,
.rewards-filter-options select:focus,
.rewards-select:focus {
  border-color: var(--wp-accent-color);
  outline: none;
}

.rewards-multi-actions {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
}

.rewards-validator-toggle {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 10px;
}

.rewards-sort-options,
.rewards-filter-options {
  display: flex;
  align-items: center;
  gap: 10px;
}

.rewards-search-input {
  padding: 8px 12px;
  border: 1px solid var(--wp-border-color);
  border-radius: 6px;
  background: var(--wp-card-bg);
  color: var(--wp-primary-text); /* Ensure text is readable in light mode */
  font-size: 0.95rem;
  width: 200px;
  margin-left: 10px;
  transition: border-color 0.3s ease;
  box-sizing: border-box;
}

.rewards-search-input:focus {
  border-color: var(--wp-accent-color);
  outline: none;
}

.rewards-bucket-card.rewards-undelegated {
  border: 2px solid var(--wp-warning);
  background: rgba(255, 204, 0, 0.1);
}

.light-mode .rewards-bucket-card.rewards-undelegated {
  background: rgba(255, 204, 0, 0.05); /* Lighter background in light mode */
}

.rewards-bucket-card.rewards-inactive {
  border: 2px solid #ff5555;
  background: rgba(255, 85, 85, 0.1);
}

.light-mode .rewards-bucket-card.rewards-inactive {
  background: rgba(255, 85, 85, 0.05); /* Lighter background in light mode */
}

.rewards-bucket-card.rewards-jailed {
  border: 2px solid #ff00ff;
  background: rgba(255, 0, 255, 0.1);
}

.light-mode .rewards-bucket-card.rewards-jailed {
  background: rgba(255, 0, 255, 0.05); /* Lighter background in light mode */
}

.rewards-button-group {
  display: flex;
  gap: 12px;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
}

/* Recommendations Section Styling */
.rewards-recommendation-group {
  margin-bottom: 20px;
}

.rewards-recommendation-group h3 {
  font-size: 1.2rem;
  color: var(--wp-primary-text);
  margin-bottom: 15px;
  padding-bottom: 5px;
  border-bottom: 1px solid var(--wp-border-color);
  text-align: center;
  text-transform: uppercase;
}

/* Recommendation Table */
.rewards-recommendation-table {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.rewards-recommendation-table-header {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 1fr;
  padding: 12px;
  background: rgba(255, 255, 255, 0.08);
  border-radius: 8px;
  font-weight: 600;
  color: var(--wp-primary-text);
  font-size: 0.95rem;
  text-align: center;
}

.light-mode .rewards-recommendation-table-header {
  background: rgba(0, 0, 0, 0.08); /* Lighter background in light mode */
}

.rewards-recommendation-group:last-child .rewards-recommendation-table-header {
  grid-template-columns: 1fr 2fr 2fr 1fr 1fr;
}

.rewards-recommendation-table-row {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 1fr;
  padding: 12px;
  background: var(--wp-card-gradient);
  border-radius: 8px;
  border: 1px solid var(--wp-border-color);
  align-items: center;
  font-size: 0.95rem;
  color: var(--wp-secondary-text);
  transition: background 0.3s ease, transform 0.2s;
  position: relative;
}

.light-mode .rewards-recommendation-table-row {
  background: var(--wp-card-gradient); /* Use lighter gradient in light mode */
}

.rewards-recommendation-group:last-child .rewards-recommendation-table-row {
  grid-template-columns: 1fr 2fr 2fr 1fr 1fr;
}

.rewards-recommendation-table-row:hover {
  background: rgba(255, 255, 255, 0.1); /* Dark mode hover effect */
  transform: translateY(-2px);
}

.light-mode .rewards-recommendation-table-row:hover {
  background: rgba(0, 0, 0, 0.1); /* Lighter hover effect in light mode */
}

.rewards-row-summary {
  display: contents;
  cursor: pointer;
}

.rewards-row-details {
  grid-column: 1 / -1;
  padding: 12px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 6px;
  margin-top: 8px;
  font-size: 0.9rem;
  color: var(--wp-secondary-text);
  display: none;
  transition: height 0.3s ease;
}

.light-mode .rewards-row-details {
  background: rgba(0, 0, 0, 0.05); /* Lighter background in light mode */
}

.rewards-recommendation-table-row.expanded .rewards-row-details {
  display: block;
}

.rewards-recommendation-table-row span {
  text-align: center;
  padding: 0 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rewards-validator-name {
  display: flex;
  align-items: center;
  gap: 6px;
  justify-content: center;
}

.rewards-validator-name-wrapper {
  display: flex;
  align-items: center;
  gap: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.rewards-validator-name-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rewards-validator-name-text:hover::after {
  content: attr(data-full);
  position: absolute;
  background: var(--wp-card-bg);
  color: var(--wp-primary-text);
  padding: 6px 10px;
  border-radius: 4px;
  z-index: 10;
  white-space: normal;
  max-width: 250px;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
}

.rewards-validator-status {
  display: inline-block;
  padding: 2px 6px;
  margin-left: 4px;
  border-radius: 3px;
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
  background: rgba(255, 255, 255, 0.1);
}

.light-mode .rewards-validator-status {
  background: rgba(0, 0, 0, 0.2); /* Slightly darker background for better contrast */
}

.rewards-status-elected {
  color: var(--wp-success);
}

.rewards-status-eligible {
  color: var(--wp-warning);
}

.rewards-status-waiting {
  color: #ffa500;
}

.rewards-status-inactive {
  color: #ff5555;
}

.rewards-status-jailed {
  color: #ff00ff;
}

.rewards-profit-gain {
  color: var(--wp-success);
  font-weight: 500;
}

.rewards-validator-details {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 8px;
  padding: 10px 0;
}

.rewards-bucket-details {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 8px;
  padding: 10px 0;
}

.rewards-validator-status i {
  margin-left: 5px;
  font-size: 0.7rem;
}

.rewards-profit-indicator {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  vertical-align: middle;
  margin-left: 5px;
}

/* Button Styling */
.rewards-button {
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid var(--wp-accent-color);
  border-radius: 8px;
  background: var(--wp-card-bg);
  color: var(--wp-primary-text);
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease, opacity 0.3s ease;
  display: flex;
  align-items: center;
  gap: 5px;
}

.rewards-button:hover {
  background: var(--wp-accent-hover);
  transform: translateY(-2px);
}

.rewards-button:disabled {
  background: rgba(255, 255, 255, 0.1);
  color: var(--wp-secondary-text);
  cursor: not-allowed;
  opacity: 0.7;
  transform: none;
}

.light-mode .rewards-button:disabled {
  background: rgba(0, 0, 0, 0.1); /* Lighter disabled background in light mode */
}

.rewards-action-button {
  background: var(--wp-accent-color);
  border: none;
  text-transform: uppercase;
  padding: 8px 16px;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  gap: 6px;
  border-radius: 6px;
  color: #ffffff; /* White text for better contrast */
}

.rewards-action-button:hover {
  background: var(--wp-accent-hover);
  transform: translateY(-2px);
}

/* Overlay Styles */
.rewards-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.light-mode .rewards-overlay {
  background: rgba(0, 0, 0, 0.5); /* Lighter overlay in light mode */
}

.rewards-overlay-content {
  position: relative;
  background: var(--wp-card-bg);
  padding: 25px;
  border-radius: 12px;
  border: 1px solid var(--wp-border-color);
  box-shadow: var(--wp-shadow);
  text-align: center;
  max-width: 450px;
  width: 90%;
  color: var(--wp-primary-text);
  box-sizing: border-box;
}

.rewards-overlay-content h3 {
  margin: 0 0 20px;
  font-size: 1.4rem;
  color: var(--wp-accent-color);
}

.rewards-overlay-content p {
  margin: 12px 0;
  font-size: 1rem;
  color: var(--wp-secondary-text);
}

.rewards-input {
  padding: 10px 14px;
  font-size: 1rem;
  border: 1px solid var(--wp-border-color);
  border-radius: 6px;
  background: var(--wp-card-bg);
  color: var(--wp-primary-text);
  width: 100%;
  margin-bottom: 20px;
  box-sizing: border-box;
  transition: border-color 0.3s ease;
}

.rewards-input:focus {
  border-color: var(--wp-accent-color);
  outline: none;
}

.rewards-close-button {
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  color: var(--wp-primary-text);
  font-size: 1.4rem;
  cursor: pointer;
  padding: 0;
  transition: color 0.3s ease;
}

.rewards-close-button:hover {
  color: var(--wp-accent-hover);
}

/* No Buckets/Validators */
.rewards-no-buckets,
.rewards-no-validators {
  font-size: 1rem;
  color: var(--wp-secondary-text);
  text-align: center;
  padding: 15px;
}

/* Validator Logo */
.rewards-validator-logo {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid var(--wp-border-color);
  margin-left: 10px;
}

.rewards-bucket-legend {
  margin-bottom: 20px;
  padding: 10px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  border: 1px solid var(--wp-border-color);
}

.light-mode .rewards-bucket-legend {
  background: rgba(0, 0, 0, 0.05); /* Lighter background in light mode */
}

.rewards-bucket-legend h3 {
  font-size: 1.1rem;
  color: var(--wp-primary-text);
  margin: 0 0 10px;
  text-align: center;
  text-transform: uppercase;
}

.rewards-legend-items {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  justify-content: center;
}

.rewards-legend-item {
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 0.9rem;
  color: var(--wp-secondary-text);
}

.rewards-legend-color {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: inline-block;
}

/* Media Queries for Mobile */
@media (max-width: 430px) {
  .rewards-rewards-container {
    margin: 10px auto;
    padding: 10px;
    gap: 15px;
  }

  .rewards-rewards-title {
    font-size: 1.2rem;
  }

  .rewards-section-title {
    font-size: 1rem;
  }

  .rewards-rewards-section,
  .rewards-buckets-section,
  .rewards-validators-section,
  .rewards-recommendations-section {
    padding: 15px;
  }

  .rewards-reward-row {
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
  }

  .rewards-reward-label {
    min-width: 100%;
    text-align: left;
  }

  .rewards-reward-value {
    text-align: left;
    margin-right: 0;
  }

  .rewards-reward-row .rewards-action-button {
    width: 100%;
    margin-top: 5px;
  }

  .rewards-wallet-info span {
    font-size: 0.75rem;
  }

  .rewards-copy-button {
    font-size: 1rem;
  }

  /* Recommendation Table Mobile */
  .rewards-recommendation-table-header {
    display: none;
  }

  .rewards-recommendation-table-row {
    grid-template-columns: 1fr !important;
    padding: 10px;
    margin-bottom: 10px;
  }

  .rewards-row-summary {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 8px;
    padding: 6px 0;
  }

  .rewards-row-summary span {
    text-align: left;
    padding: 0;
    width: 100%;
    font-size: 0.85rem;
  }

  .rewards-row-summary span:nth-child(1) {
    font-weight: 600;
    color: var(--wp-primary-text);
  }

  .rewards-row-summary span:nth-child(2),
  .rewards-row-summary span:nth-child(3) {
    color: var(--wp-secondary-text);
  }

  .rewards-row-summary span:last-child {
    text-align: center;
    margin-top: 8px;
  }

  .rewards-recommendation-table-row .rewards-action-button {
    width: 100%;
    padding: 8px;
    font-size: 0.8rem;
  }

  .rewards-row-details {
    padding: 10px;
    margin-top: 10px;
    font-size: 0.8rem;
  }

  .rewards-validator-status {
    font-size: 0.65rem;
  }

  .rewards-bucket-filter,
  .rewards-validator-toggle {
    flex-direction: column;
    align-items: stretch;
    gap: 15px;
    padding: 0;
  }

  .rewards-multi-actions {
    flex-direction: column;
    gap: 8px;
  }

  .rewards-bucket-filter label,
  .rewards-sort-options label,
  .rewards-filter-options label {
    font-size: 0.9rem;
    min-width: 60px;
    text-align: left;
  }

  .rewards-bucket-filter select,
  .rewards-sort-options select,
  .rewards-filter-options select,
  .rewards-select,
  .rewards-search-input {
    width: 100%;
    max-width: none;
    font-size: 0.8rem;
    margin-left: 0;
    margin-top: 0;
    padding: 6px 10px;
    box-sizing: border-box;
  }

  .rewards-validator-toggle > * {
    margin-left: 0 !important;
    width: 100%;
  }

  .rewards-validator-details,
  .rewards-bucket-details {
    grid-template-columns: 1fr;
  }

  .rewards-overlay-content {
    padding: 15px;
  }

  .rewards-overlay-content h3 {
    font-size: 1rem;
  }

  .rewards-overlay-content p {
    font-size: 0.8rem;
  }

  .rewards-input {
    font-size: 0.8rem;
  }

  .rewards-no-buckets,
  .rewards-no-validators {
    font-size: 0.9rem;
  }

  .rewards-info-label,
  .rewards-info-value,
  .rewards-reward-label,
  .rewards-reward-value {
    font-size: 0.85rem;
  }

  .rewards-button,
  .rewards-action-button {
    font-size: 0.8rem;
  }

  .rewards-sort-options,
  .rewards-filter-options {
    flex-direction: row;
    align-items: center;
    width: 100%;
    gap: 8px;
  }

  .rewards-bucket-legend {
    padding: 8px;
  }

  .rewards-bucket-legend h3 {
    font-size: 0.95rem;
  }

  .rewards-legend-items {
    gap: 10px;
    flex-direction: column;
    align-items: flex-start;
  }

  .rewards-legend-item {
    font-size: 0.85rem;
  }

  .rewards-legend-color {
    width: 10px;
    height: 10px;
  }
}

/* Desktop Adjustments */
@media (min-width: 431px) {
  .rewards-rewards-title {
    font-size: 2.5rem;
  }

  .rewards-reward-row {
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }

  .rewards-reward-label {
    min-width: 120px;
  }

  .rewards-reward-value {
    text-align: right;
  }

  .rewards-reward-row .rewards-action-button {
    width: auto;
  }
}