/* AssetDetailsPage.css */

/* Custom Theme Variables */
:root {
  /* Default (dark mode) */
  --ad-bg-color: #1e1e33;
  --ad-primary-text: #ffffff;
  --ad-secondary-text: #a0a0c0;
  --ad-accent-color: #8a2be2;
  --ad-accent-hover: #b72be2;
  --ad-border-color: rgba(255, 255, 255, 0.1);
  --ad-card-bg: #2d2d4a;
  --ad-shadow: 0 8px 20px rgba(0, 0, 0, 0.5);
}

.light-mode {
  /* Light mode overrides */
  --ad-bg-color: #ffffff;
  --ad-primary-text: #333333;
  --ad-secondary-text: #666666;
  --ad-accent-color: #6a1b9a; /* Softer purple for light mode */
  --ad-accent-hover: #8a2be2;
  --ad-border-color: rgba(0, 0, 0, 0.1);
  --ad-card-bg: #f0f0f0;
  --ad-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
}

/* Container */
.asset-details-container {
  width: 90%;
  max-width: 1200px;
  margin: 40px auto;
  padding: 32px;
  background: linear-gradient(135deg, var(--ad-bg-color), #2a2a4f);
  border-radius: 20px;
  box-shadow: var(--ad-shadow);
  color: var(--ad-primary-text);
  font-family: 'Inter', Arial, sans-serif;
  transition: all 0.3s ease; /* Smooth transition for theme changes */
}

.light-mode .asset-details-container {
  background: linear-gradient(135deg, var(--ad-bg-color), #e0e0e0); /* Lighter gradient for light mode */
}

/* Header */
.asset-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  padding: 0 16px;
}
.asset-title {
  font-size: 2.25rem;
  font-weight: 700;
  margin: 0;
  color: var(--ad-primary-text);
}
.wallet-stats {
  display: flex;
  gap: 12px;
  font-size: 0.95rem;
  color: var(--ad-secondary-text);
}
.wallet-stats span {
  background: rgba(255, 255, 255, 0.05);
  padding: 8px 12px;
  border-radius: 12px;
  border: 1px solid var(--ad-border-color);
}

.light-mode .wallet-stats span {
  background: rgba(0, 0, 0, 0.05); /* Lighter background in light mode */
}

/* Pagination Controls */
.pagination-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  margin: 20px 0;
  padding: 0 16px;
}

.pagination-btn {
  padding: 8px 16px;
  background: #4a4a6a; /* Flat dark background */
  color: #ffffff;
  border: 1px solid var(--ad-accent-color); /* Purple border for accent */
  border-radius: 8px;
  font-size: 0.9rem;
  font-weight: 600;
  cursor: pointer;
  transition: background 0.2s ease, border-color 0.2s ease;
}

.pagination-btn:hover {
  background: #5a5a7a; /* Slightly lighter on hover */
  border-color: var(--ad-accent-hover); /* Lighter purple on hover */
}

.pagination-btn:disabled {
  background: #33334d;
  border-color: #444;
  cursor: not-allowed;
  color: #a0a0c0;
}

.light-mode .pagination-btn {
  background: #e0e0e0; /* Light gray background in light mode */
  color: var(--ad-primary-text); /* Dark text for contrast */
  border-color: var(--ad-accent-color);
}

.light-mode .pagination-btn:hover {
  background: #d0d0d0; /* Slightly darker gray on hover */
  border-color: var(--ad-accent-hover);
}

.light-mode .pagination-btn:disabled {
  background: #d3d3d3;
  border-color: #999;
  color: var(--ad-secondary-text);
}

.page-counter {
  font-size: 0.95rem;
  color: var(--ad-primary-text);
}

/* Bulk Action Tabs */
.bulk-action-tabs {
  display: flex;
  justify-content: center;
  gap: 16px;
  flex-wrap: wrap;
  padding: 16px 16px;
  border-bottom: 1px solid var(--ad-border-color);
  margin-bottom: 24px;
}

.bulk-action-button-tab {
  padding: 10px 20px;
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  color: var(--ad-secondary-text);
  transition: color 0.3s, border-bottom 0.3s;
  border-bottom: 2px solid transparent;
  display: flex;
  align-items: center;
  gap: 8px;
}

.bulk-action-button-tab i {
  font-size: 1.1rem;
}

.bulk-action-button-tab.active {
  color: var(--ad-primary-text);
  border-bottom: 2px solid var(--ad-primary-text);
}

.bulk-action-button-tab:hover {
  color: var(--ad-primary-text);
}

/* Asset Grid */
.asset-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  gap: 16px;
  padding: 0 16px;
  margin-bottom: 24px;
}

/* Asset Card */
.asset-card {
  position: relative;
  background: var(--ad-card-bg);
  border-radius: 12px;
  padding: 16px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease, border-color 0.3s ease;
  cursor: pointer;
  border: 1px solid transparent;
}

.asset-card.selected {
  border: 1px solid var(--ad-accent-color);
}

.asset-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 20px rgba(138, 43, 226, 0.2);
}

.light-mode .asset-card {
  background: var(--ad-card-bg); /* Light gray background */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* Lighter shadow in light mode */
}

.light-mode .asset-card:hover {
  box-shadow: 0 8px 20px rgba(106, 27, 154, 0.2); /* Light mode hover shadow with accent color */
}

.nft-id {
  font-weight: 600;
  font-size: 0.9rem;
  color: var(--ad-secondary-text);
  margin-bottom: 12px;
}

/* NFT Image */
.asset-image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.nft-image {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

/* Metadata Attributes */
.metadata-attributes-container {
  text-align: left;
  padding: 8px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  max-height: 150px; /* Prevent overflow */
  overflow-y: auto;
}

.light-mode .metadata-attributes-container {
  background: rgba(0, 0, 0, 0.05); /* Lighter background in light mode */
}

.metadata-attribute {
  margin-bottom: 6px;
  font-size: 0.9rem;
}

.metadata-trait-type {
  font-weight: 600;
  color: var(--ad-secondary-text);
}

.metadata-value {
  margin-left: 8px;
  color: var(--ad-primary-text);
}

/* Modals */
.confirmation-modal {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.2s;
}

.light-mode .confirmation-modal {
  background: rgba(0, 0, 0, 0.5); /* Lighter overlay in light mode */
}

.modal-content {
  background: var(--ad-card-bg);
  padding: 24px;
  border-radius: 16px;
  width: 90%;
  max-width: 400px;
  text-align: center;
  position: relative;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
}

.light-mode .modal-content {
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); /* Lighter shadow in light mode */
}

.modal-content h2 {
  font-size: 1.5rem;
  margin: 0 0 16px;
  color: var(--ad-primary-text);
}

/* Modal Inputs */
.modal-inputs {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.input-group {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%; /* Ensure inputs stay within parent */
}

.checkbox-group {
  display: flex;
  align-items: center;
  gap: 8px;
}

.modal-label {
  font-size: 0.9rem;
  color: var(--ad-secondary-text);
  margin-bottom: 6px;
}

.modal-input,
.modal-select {
  width: 100%;
  padding: 10px;
  border: 1px solid var(--ad-border-color);
  border-radius: 8px;
  background: #3a3a5a;
  color: var(--ad-primary-text);
  font-size: 1rem;
  box-sizing: border-box; /* Ensure inputs stay within parent */
}

.light-mode .modal-input,
.light-mode .modal-select {
  background: #ffffff; /* White background in light mode */
  border-color: var(--ad-border-color); /* Darker border in light mode */
}

.modal-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #3a3a5a url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCI+PHBhdGggZD0iTTUgMTBsNS41IDUuNSA1LjUtNS41TDIwIDE1bC01LjUgNS41LTUuNS01LjV6IiBmaWxsPSIjZmZmZmZmIi8+PC9zdmc+') no-repeat right 10px center;
  background-size: 12px;
}

.light-mode .modal-select {
  background: #ffffff url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCI+PHBhdGggZD0iTTUgMTBsNS41IDUuNSA1LjUtNS41TDIwIDE1bC01LjUgNS41LTUuNS01LjV6IiBmaWxsPSIjNjY2NjY2Ii8+PC9zdmc+') no-repeat right 10px center; /* Darker arrow in light mode */
}

.modal-input:focus,
.modal-select:focus {
  outline: none;
  border-color: var(--ad-accent-color);
}

.modal-checkbox {
  margin-right: 8px;
}

.checkbox-label {
  font-size: 0.9rem;
  color: var(--ad-primary-text);
}

/* Modal Actions */
.modal-actions {
  display: flex;
  gap: 12px;
  justify-content: center;
  margin-top: 20px;
}

.action-button,
.action-button.cancel {
  padding: 10px 20px;
  border: 1px solid var(--ad-accent-color); /* Flat design with border */
  border-radius: 8px;
  font-size: 0.9rem;
  font-weight: 600;
  cursor: pointer;
  transition: background 0.2s ease, border-color 0.2s ease;
  background: #4a4a6a; /* Flat dark background */
  color: #ffffff;
}

.action-button:hover {
  background: #5a5a7a; /* Slightly lighter on hover */
  border-color: var(--ad-accent-hover); /* Lighter purple on hover */
}

.action-button.cancel {
  background: #4a4a6a;
  color: #ffffff;
}

.action-button.cancel:hover {
  background: #5a5a7a;
}

.light-mode .action-button,
.light-mode .action-button.cancel {
  background: #e0e0e0; /* Light gray background in light mode */
  color: var(--ad-primary-text); /* Dark text for contrast */
  border-color: var(--ad-accent-color);
}

.light-mode .action-button:hover,
.light-mode .action-button.cancel:hover {
  background: #d0d0d0; /* Slightly darker gray on hover */
  border-color: var(--ad-accent-hover);
}

/* Transaction Status */
.transaction-status {
  margin-top: 16px;
  font-size: 0.9rem;
  color: var(--ad-accent-color);
}

/* Loading State */
.loading {
  text-align: center;
  margin: 50px 0;
  font-size: 1.1rem;
  color: var(--ad-primary-text);
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

/* Responsive Adjustments */
@media (max-width: 430px) {
  .asset-details-container {
    margin: 16px auto;
    padding: 20px;
  }
  .asset-header {
    flex-direction: column;
    gap: 12px;
    padding: 0;
  }
  .asset-title {
    font-size: 1.75rem;
  }
  .wallet-stats {
    justify-content: center;
  }
  .asset-grid {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 12px;
    padding: 0;
  }
  .asset-card {
    padding: 12px;
  }
  .nft-id {
    font-size: 0.85rem;
  }
  .nft-image {
    margin-bottom: 8px;
  }
  .metadata-attributes-container {
    padding: 6px;
    max-height: 120px; /* Reduced for mobile */
  }
  .metadata-attribute {
    font-size: 0.8rem;
  }
  .bulk-action-tabs {
    gap: 8px;
    padding: 12px 0;
  }
  .bulk-action-button-tab {
    padding: 8px 16px;
    font-size: 0.9rem;
  }
  .bulk-action-button-tab i {
    font-size: 1rem;
  }
  .modal-content {
    padding: 16px;
    max-width: 300px;
  }
  .modal-content h2 {
    font-size: 1.3rem;
  }
  .modal-inputs {
    gap: 12px;
  }
  .modal-label {
    font-size: 0.85rem;
  }
  .modal-input,
  .modal-select {
    padding: 8px;
    font-size: 0.9rem;
  }
  .action-button,
  .action-button.cancel {
    padding: 8px 16px;
    font-size: 0.85rem;
  }
}