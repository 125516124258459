/* Custom Theme Variables */
:root {
  /* Default (dark mode) */
  --wm-bg-color: #1e1e33;
  --wm-primary-text: #ffffff;
  --wm-secondary-text: #a0a0c0;
  --wm-accent-color: #8a2be2;
  --wm-accent-hover: #b72be2;
  --wm-border-color: rgba(255, 255, 255, 0.1);
  --wm-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
}

.light-mode {
  /* Light mode overrides */
  --wm-bg-color: #ffffff;
  --wm-primary-text: #333333;
  --wm-secondary-text: #666666;
  --wm-accent-color: #6a1b9a; /* Softer purple for light mode */
  --wm-accent-hover: #8a2be2;
  --wm-border-color: rgba(0, 0, 0, 0.1);
  --wm-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); /* Lighter shadow in light mode */
}

/* Overlay stays full-screen */
.wallet-modal-overlay {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(5px);
  z-index: 1000;
  animation: fadeIn 0.2s ease-in;
}

.light-mode .wallet-modal-overlay {
  background: rgba(0, 0, 0, 0.5); /* Lighter overlay in light mode */
}

/* Modal Content: Top-right position for both desktop and mobile */
.wallet-modal-content {
  position: fixed;
  top: 80px; /* Position below the header (adjust if header height changes) */
  right: 20px;
  background: var(--wm-bg-color);
  padding: 16px;
  border-radius: 12px;
  border: 1px solid var(--wm-border-color);
  width: 300px;
  box-shadow: var(--wm-shadow);
  display: flex;
  flex-direction: column;
  animation: slideIn 0.3s ease-out;
  color: var(--wm-primary-text);
  box-sizing: border-box; /* Ensure no overflow */
  transition: all 0.3s ease; /* Smooth transition for theme changes */
}

/* Adjustments for smaller screens (≤430px) to keep top-right positioning */
@media (max-width: 430px) {
  .wallet-modal-content {
    top: 60px; /* Position below the mobile header (adjust if mobile header height changes) */
    right: 10px;
    width: 90%;
    max-width: 280px; /* Slightly smaller on mobile for better fit */
    padding: 12px;
  }
}

/* Modal Header */
.wallet-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 12px;
  border-bottom: 1px solid var(--wm-border-color);
}

.wallet-modal-header h2 {
  margin: 0;
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--wm-primary-text); /* Explicitly set text color to adapt to theme */
}

.wallet-close-button {
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: var(--wm-primary-text);
  transition: color 0.2s;
}

.wallet-close-button:hover {
  color: var(--wm-accent-color);
}

/* Wallet Balance */
.wallet-balance {
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.balance-header {
  display: flex;
  align-items: center;
  gap: 8px;
}

.klever-logo {
  width: 32px;
  height: 32px;
  border-radius: 4px;
}

.balance-amount {
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--wm-accent-color);
}

.wallet-address {
  font-size: 0.9rem;
  color: var(--wm-secondary-text);
  background: rgba(255, 255, 255, 0.05);
  padding: 8px 12px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: background 0.2s ease;
}

.light-mode .wallet-address {
  background: rgba(0, 0, 0, 0.05); /* Lighter background in light mode */
}

.wallet-address:hover {
  background: rgba(255, 255, 255, 0.1); /* Dark mode hover effect */
}

.light-mode .wallet-address:hover {
  background: rgba(0, 0, 0, 0.1); /* Lighter hover effect in light mode */
}

.copy-icon {
  font-size: 1rem;
  margin-left: 8px;
}

/* Modal Menu */
.wallet-modal-menu {
  display: flex;
  flex-direction: column;
  margin-top: 12px;
}

.menu-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 8px;
  border-top: 1px solid var(--wm-border-color);
  cursor: pointer;
  transition: background 0.2s ease;
  font-size: 0.9rem;
}

.menu-item:hover {
  background: rgba(255, 255, 255, 0.05); /* Dark mode hover effect */
}

.light-mode .menu-item:hover {
  background: rgba(0, 0, 0, 0.05); /* Lighter hover effect in light mode */
}

.menu-item span {
  font-size: 0.9rem;
}

.arrow {
  color: var(--wm-accent-color);
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}