/* Font Import */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css');

/* Custom Theme Variables (Matching Previous Pages) */
:root {
  /* Default (dark mode) */
  --c-bg-color: #0f0f1a;
  --c-primary-text: #e0e0ff;
  --c-secondary-text: #a0a0d0;
  --c-accent-color: #7b00ff;
  --c-accent-hover: #9b3bff;
  --c-border-color: rgba(255, 255, 255, 0.1);
  --c-table-bg: #1a1a2e;
  --c-shadow: 0 8px 24px rgba(0, 0, 0, 0.4);
}

.light-mode {
  /* Light mode overrides */
  --c-bg-color: #ffffff;
  --c-primary-text: #333333;
  --c-secondary-text: #666666;
  --c-accent-color: #6a1b9a; /* Softer purple for light mode */
  --c-accent-hover: #8a2be2;
  --c-border-color: rgba(0, 0, 0, 0.1);
  --c-table-bg: #f5f5f5;
  --c-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
}

/* Main Layout */
.pre-sale-page {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  background: var(--c-bg-color);
  color: var(--c-primary-text);
  font-family: 'Inter', Arial, sans-serif;
  transition: all 0.3s ease; /* Smooth transition for theme changes */
}

/* Pre-Sale Container */
.pre-sale-container {
  width: 100%;
  max-width: 1200px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  background: var(--c-table-bg);
  border-radius: 12px;
  box-shadow: var(--c-shadow);
  padding: 40px;
  position: relative;
}

/* Back Button */
.back-btn {
  position: absolute;
  top: 20px;
  left: 20px;
  background: none;
  border: 1px solid var(--c-border-color);
  color: var(--c-primary-text);
  padding: 8px 12px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.back-btn:hover {
  background: rgba(255, 255, 255, 0.1);
}

.light-mode .back-btn:hover {
  background: rgba(0, 0, 0, 0.05); /* Lighter hover effect in light mode */
}

/* Header Section */
.pre-sale-header {
  text-align: center;
}

.pre-sale-logo {
  width: 100px;
  height: 100px;
  border-radius: 12px;
  margin-bottom: 20px;
}

.light-mode .pre-sale-logo {
  border: 1px solid var(--c-border-color); /* Ensure logo border is visible in light mode */
}

.pre-sale-header h1 {
  font-size: 2.5rem;
  font-weight: 700;
  margin: 0 0 15px;
  color: var(--c-primary-text);
}

.description {
  font-size: 1.1rem;
  color: var(--c-secondary-text);
  margin: 0 0 20px;
}

.short-text {
  display: inline;
}

.full-text {
  display: block;
  margin-top: 10px;
}

.toggle-description {
  background: none;
  border: none;
  color: var(--c-accent-color);
  font-size: 0.9rem;
  cursor: pointer;
  padding: 0;
  margin-left: 8px;
}

.progress-bar {
  width: 100%;
  background: #252540;
  border-radius: 12px;
  height: 15px;
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
}

.light-mode .progress-bar {
  background: #e5e5e5; /* Lighter progress bar background in light mode */
}

.progress-fill {
  height: 100%;
  background: var(--c-accent-color);
  transition: width 0.5s ease;
}

.progress-bar span {
  position: absolute;
  top: 18px;
  left: 0;
  width: 100%;
  font-size: 1rem;
  color: var(--c-secondary-text);
  text-align: center;
}

.countdown {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--c-primary-text);
  background: rgba(255, 255, 255, 0.05);
  padding: 8px 15px;
  border-radius: 12px;
  margin-bottom: 20px;
}

.light-mode .countdown {
  background: rgba(0, 0, 0, 0.05); /* Lighter background in light mode */
}

.info-grid {
  display: flex;
  justify-content: space-around;
  font-size: 1rem;
  color: var(--c-secondary-text);
  margin-bottom: 20px;
}

.social-links {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.social-links a {
  color: var(--c-secondary-text);
  font-size: 1.5rem;
  transition: color 0.3s ease;
}

.social-links a:hover {
  color: var(--c-accent-hover);
}

/* Form Section */
.pre-sale-form {
  padding: 30px;
  background: #252540;
  border-radius: 12px;
}

.light-mode .pre-sale-form {
  background: #e5e5e5; /* Lighter form background in light mode */
}

.input-group {
  position: relative;
  margin-bottom: 20px;
}

.balance-label {
  display: block;
  font-size: 1rem;
  color: var(--c-secondary-text);
  margin-bottom: 8px;
}

.input-group input {
  width: 100%;
  padding: 12px;
  background: var(--c-table-bg);
  border: 1px solid var(--c-border-color);
  border-radius: 8px;
  color: var(--c-primary-text);
  font-size: 1.1rem;
  box-sizing: border-box;
}

.light-mode .input-group input {
  background: #ffffff; /* White input background in light mode */
}

.input-label {
  position: absolute;
  top: 50%;
  right: 10px;
  font-size: 1rem;
  color: var(--c-secondary-text);
}

.percentage-buttons {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.percentage-buttons button {
  flex: 1;
  padding: 10px;
  background: var(--c-table-bg);
  border: 1px solid var(--c-border-color);
  border-radius: 8px;
  color: var(--c-primary-text);
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.light-mode .percentage-buttons button {
  background: #ffffff; /* White button background in light mode */
}

.percentage-buttons button:hover {
  background: var(--c-accent-color);
}

.percentage-buttons button:disabled {
  background: #4a4a6a;
  cursor: not-allowed;
}

.light-mode .percentage-buttons button:disabled {
  background: #d0d0d0; /* Lighter disabled button in light mode */
}

.connect-btn, .action-btn {
  width: 100%;
  padding: 12px;
  background: var(--c-accent-color);
  border: none;
  border-radius: 8px;
  color: var(--c-primary-text);
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-bottom: 15px;
}

.connect-btn.connected {
  background: #4a4a6a;
  cursor: default;
}

.light-mode .connect-btn.connected {
  background: #d0d0d0; /* Lighter connected button in light mode */
}

.connect-btn:hover:not(.connected), .action-btn:hover:not(:disabled) {
  background: var(--c-accent-hover);
}

.action-btn:disabled {
  background: #4a4a6a;
  cursor: not-allowed;
}

.light-mode .action-btn:disabled {
  background: #d0d0d0; /* Lighter disabled button in light mode */
}

.status-message {
  font-size: 0.95rem;
  color: var(--c-secondary-text);
  text-align: center;
  min-height: 40px;
}

.install-btn {
  background: #ffca28; /* Yellow for visibility */
  color: #1a1a2e;
  padding: 8px 15px;
  border-radius: 8px;
  border: none;
  font-size: 0.95rem;
  font-weight: 600;
  cursor: pointer;
  margin-top: 10px;
  transition: all 0.3s ease;
}

.light-mode .install-btn {
  color: #333333; /* Darker text in light mode */
}

.install-btn:hover {
  background: #ffb300;
}

/* Contributors Section */
.contributors-section {
  grid-column: span 2;
  padding: 30px;
}

.toggle-contributors {
  width: 100%;
  background: none;
  border: 1px solid var(--c-border-color);
  border-radius: 8px;
  color: var(--c-primary-text);
  font-size: 1.1rem;
  font-weight: 600;
  padding: 10px;
  cursor: pointer;
  text-align: center;
  transition: all 0.3s ease;
  margin-bottom: 20px;
}

.toggle-contributors:hover {
  background: rgba(255, 255, 255, 0.1);
}

.light-mode .toggle-contributors:hover {
  background: rgba(0, 0, 0, 0.05); /* Lighter hover effect in light mode */
}

.contributors-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-height: 300px;
  overflow-y: auto;
}

.contributor-item {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  background: #252540;
  border-radius: 8px;
  font-size: 1rem;
}

.light-mode .contributor-item {
  background: #e5e5e5; /* Lighter background in light mode */
}

.contributor-item span:first-child {
  color: var(--c-primary-text);
}

.contributor-item span:last-child {
  color: var(--c-accent-color);
  font-weight: 600;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .pre-sale-container {
    grid-template-columns: 1fr;
    padding: 20px;
    gap: 20px;
  }

  .contributors-section {
    grid-column: span 1;
  }

  .pre-sale-logo {
    width: 80px;
    height: 80px;
  }

  .pre-sale-header h1 {
    font-size: 2rem;
  }

  .description {
    font-size: 1rem;
  }

  .progress-bar {
    height: 12px;
  }

  .progress-bar span {
    font-size: 0.9rem;
    top: 15px;
  }

  .countdown {
    font-size: 1.1rem;
  }

  .info-grid {
    font-size: 0.9rem;
  }

  .social-links a {
    font-size: 1.2rem;
  }
}

@media (max-width: 430px) {
  .pre-sale-page {
    padding: 20px;
  }

  .pre-sale-container {
    padding: 10px;
    gap: 10px;
  }

  .back-btn {
    top: 10px;
    left: 10px;
    padding: 5px 10px;
    font-size: 0.9rem;
  }

  .pre-sale-logo {
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
  }

  .pre-sale-header h1 {
    font-size: 1.25rem;
  }

  .description {
    font-size: 0.75rem;
  }

  .toggle-description {
    font-size: 0.7rem;
  }

  .progress-bar {
    height: 6px;
    margin-bottom: 10px;
  }

  .progress-bar span {
    font-size: 0.7rem;
    top: 8px;
  }

  .countdown {
    font-size: 0.8rem;
    padding: 4px 8px;
    margin-bottom: 10px;
  }

  .info-grid {
    flex-direction: column;
    gap: 5px;
    font-size: 0.7rem;
    margin-bottom: 10px;
  }

  .pre-sale-form {
    padding: 15px;
  }

  .balance-label {
    font-size: 0.7rem;
    margin-bottom: 5px;
  }

  .input-group input {
    padding: 6px;
    font-size: 0.8rem;
  }

  .input-label {
    font-size: 0.7rem;
  }

  .percentage-buttons {
    gap: 5px;
    margin-bottom: 10px;
  }

  .percentage-buttons button {
    padding: 4px;
    font-size: 0.7rem;
  }

  .connect-btn, .action-btn {
    padding: 6px;
    font-size: 0.8rem;
    margin-bottom: 10px;
  }

  .status-message {
    font-size: 0.7rem;
    min-height: 20px;
  }

  .install-btn {
    padding: 4px 8px;
    font-size: 0.7rem;
    margin-top: 5px;
  }

  .toggle-contributors {
    font-size: 0.8rem;
    padding: 4px;
    margin-bottom: 10px;
  }

  .contributors-section {
    padding: 10px;
  }

  .contributors-list {
    gap: 8px;
    max-height: 100px;
  }

  .contributor-item {
    font-size: 0.7rem;
    padding: 4px 8px;
  }

  .social-links {
    gap: 10px;
    margin-top: 10px;
  }

  .social-links a {
    font-size: 0.9rem;
  }
}