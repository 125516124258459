/* Custom Theme Variables */
:root {
  /* Default (dark mode) */
  --td-bg-color: #1e1e33;
  --td-primary-text: #ffffff;
  --td-secondary-text: #a0a0c0;
  --td-accent-color: #8a2be2;
  --td-accent-hover: #b72be2;
  --td-border-color: rgba(255, 255, 255, 0.1);
  --td-card-bg: #2d2d4a;
  --td-shadow: 0 8px 20px rgba(0, 0, 0, 0.5);
}

.light-mode {
  /* Light mode overrides */
  --td-bg-color: #ffffff;
  --td-primary-text: #333333;
  --td-secondary-text: #666666;
  --td-accent-color: #6a1b9a; /* Softer purple for light mode */
  --td-accent-hover: #8a2be2;
  --td-border-color: rgba(0, 0, 0, 0.1);
  --td-card-bg: #f0f0f0;
  --td-shadow: 0 8px 20px rgba(0, 0, 0, 0.1); /* Lighter shadow in light mode */
}

/* Container */
.token-details-container {
  width: 90%;
  max-width: 1200px;
  margin: 40px auto;
  padding: 32px;
  background: linear-gradient(135deg, var(--td-bg-color), #2a2a4f);
  border-radius: 20px;
  box-shadow: var(--td-shadow);
  color: var(--td-primary-text);
  font-family: 'Inter', Arial, sans-serif;
  min-height: 100vh;
  transition: all 0.3s ease; /* Smooth transition for theme changes */
}

.light-mode .token-details-container {
  background: linear-gradient(135deg, var(--td-bg-color), #e0e0e0); /* Lighter gradient in light mode */
}

/* Header */
.token-details-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px; /* Reduced for compactness */
  padding: 0 16px;
}

.token-details-main-token-logo {
  width: 96px;
  height: 96px;
  border-radius: 12px;
  object-fit: cover;
  border: 2px solid var(--td-accent-color);
  box-shadow: 0 4px 15px rgba(138, 43, 226, 0.3);
  margin-bottom: 12px; /* Reduced margin for compactness */
}

.light-mode .token-details-main-token-logo {
  box-shadow: 0 4px 15px rgba(106, 27, 154, 0.3); /* Adjusted for light mode accent */
}

.token-details-page-title {
  font-size: 2.25rem;
  font-weight: 700;
  margin: 0;
  color: var(--td-primary-text);
}

/* Sections */
.token-details-sections {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px; /* Reduced gap for compactness */
  max-width: 1200px;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .token-details-sections {
    grid-template-columns: 1fr;
  }
}

/* Token Card & User Info */
.token-details-card,
.token-details-user-info {
  background: var(--td-card-bg);
  border-radius: 12px; /* Slightly smaller radius for compactness */
  padding: 16px; /* Reduced padding for compactness */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2); /* Reduced shadow for compactness */
}

.light-mode .token-details-card,
.light-mode .token-details-user-info {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05); /* Lighter shadow in light mode */
}

/* Token Details Grid */
.token-details-details-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr)); /* Slightly smaller columns */
  gap: 12px; /* Reduced gap for compactness */
}

.token-details-detail-item {
  display: flex;
  flex-direction: column;
}

.token-details-detail-label {
  font-size: 0.85rem; /* Reduced font size for compactness */
  color: var(--td-secondary-text);
  text-transform: uppercase;
}

.token-details-detail-value {
  font-size: 1rem; /* Reduced font size for compactness */
  font-weight: 600;
  color: var(--td-primary-text);
}

/* User Info Title */
.token-details-user-info-title {
  font-size: 1.5rem; /* Reduced font size for compactness */
  margin-bottom: 12px; /* Reduced margin for compactness */
  color: var(--td-primary-text);
}

/* User Details Grid */
.token-details-user-details-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 12px; /* Reduced gap for compactness */
}

@media (min-width: 768px) {
  .token-details-user-details-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 992px) {
  .token-details-user-details-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

/* User Detail */
.token-details-user-detail {
  display: flex;
  flex-direction: column;
  gap: 6px; /* Reduced gap for compactness */
}

.token-details-detail-label {
  font-size: 0.85rem;
  color: var(--td-secondary-text);
  text-transform: uppercase;
}

.token-details-detail-value {
  font-size: 1rem;
  font-weight: 600;
  color: var(--td-primary-text);
}

.token-details-user-detail-buttons {
  display: flex;
  gap: 6px; /* Reduced gap for compactness */
  margin-top: 6px; /* Reduced margin for compactness */
  margin-bottom: 6px; /* Reduced margin for compactness */
}

.token-details-user-detail-buttons button,
.token-details-rewards-buttons button,
.token-details-action-modal-actions button {
  padding: 6px 12px; /* Reduced padding for compactness */
  background: #4a4a6a;
  color: var(--td-primary-text); /* Use theme variable for text color */
  border: 1px solid var(--td-accent-color);
  border-radius: 6px; /* Smaller radius for compactness */
  font-size: 0.8rem; /* Reduced font size for compactness */
  font-weight: 600;
  cursor: pointer;
  transition: background 0.2s ease, border-color 0.2s ease;
}

.light-mode .token-details-user-detail-buttons button,
.light-mode .token-details-rewards-buttons button,
.light-mode .token-details-action-modal-actions button {
  background: #d0d0d0; /* Lighter background in light mode */
}

.token-details-user-detail-buttons button:hover,
.token-details-rewards-buttons button:hover,
.token-details-action-modal-actions button:hover {
  background: #5a5a7a;
  border-color: var(--td-accent-hover);
}

.light-mode .token-details-user-detail-buttons button:hover,
.light-mode .token-details-rewards-buttons button:hover,
.light-mode .token-details-action-modal-actions button:hover {
  background: #c0c0c0; /* Slightly darker on hover in light mode */
}

.token-details-user-detail-buttons button:disabled,
.token-details-rewards-buttons button:disabled,
.token-details-action-modal-actions button:disabled {
  background: #33334d;
  border-color: #444;
  cursor: not-allowed;
  color: var(--td-secondary-text);
}

.token-details-user-detail-buttons button:focus,
.token-details-rewards-buttons button:focus,
.token-details-action-modal-actions button:focus {
  outline: none;
  border-color: var(--td-accent-color);
  box-shadow: 0 0 0 2px rgba(138, 43, 226, 0.3);
}

.light-mode .token-details-user-detail-buttons button:focus,
.light-mode .token-details-rewards-buttons button:focus,
.light-mode .token-details-action-modal-actions button:focus {
  box-shadow: 0 0 0 2px rgba(106, 27, 154, 0.3); /* Adjusted for light mode accent */
}

/* Rewards List */
.token-details-rewards-list {
  display: flex;
  flex-direction: column;
  gap: 6px; /* Reduced gap for compactness */
}

.token-details-reward-item {
  display: flex;
  align-items: center;
  gap: 8px; /* Slightly reduced gap for compactness */
}

.token-details-reward-logo {
  width: 24px; /* Reduced size for compactness */
  height: 24px;
  border-radius: 6px; /* Smaller radius for compactness */
  object-fit: cover;
}

.token-details-reward-value {
  font-size: 0.85rem; /* Reduced font size for compactness */
  color: var(--td-accent-color);
}

.token-details-no-rewards {
  font-size: 0.85rem;
  color: var(--td-secondary-text);
}

/* Loading & Error */
.token-details-loading,
.token-details-error {
  text-align: center;
  padding: 16px; /* Reduced padding for compactness */
}

.token-details-loading { /* Style Spinner directly in CSS if needed, but already handled by Spinner component */
}

.token-details-error {
  color: #ff4444;
  font-size: 1rem; /* Reduced font size for compactness */
}

/* Modals */
.token-details-action-modal {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.2s;
}

.light-mode .token-details-action-modal {
  background: rgba(0, 0, 0, 0.5); /* Lighter overlay in light mode */
}

.token-details-action-modal-content {
  background: var(--td-card-bg);
  padding: 16px; /* Reduced padding for compactness */
  border-radius: 12px; /* Smaller radius for compactness */
  width: 90%;
  max-width: 300px; /* Reduced max-width for compactness */
  text-align: center;
  position: relative;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2); /* Reduced shadow for compactness */
}

.light-mode .token-details-action-modal-content {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05); /* Lighter shadow in light mode */
}

.token-details-action-modal-content h2 {
  font-size: 1.2rem; /* Reduced font size for compactness */
  margin: 0 0 12px; /* Reduced margin for compactness */
  color: var(--td-primary-text);
}

.token-details-modal-close {
  position: absolute;
  top: 8px;
  right: 8px;
  background: transparent;
  border: none;
  color: var(--td-primary-text);
  font-size: 1.2rem; /* Reduced font size for compactness */
  cursor: pointer;
  transition: color 0.3s ease;
}

.token-details-modal-close:hover {
  color: var(--td-accent-color);
}

/* Modal Inputs */
.token-details-modal-input-group {
  margin-bottom: 12px; /* Reduced margin for compactness */
  text-align: left;
}

.token-details-modal-input-group label {
  display: block;
  font-size: 0.8rem; /* Reduced font size for compactness */
  color: var(--td-secondary-text);
  margin-bottom: 4px; /* Reduced margin for compactness */
}

.token-details-modal-input-group input {
  width: 100%;
  padding: 8px; /* Reduced padding for compactness */
  border: 1px solid var(--td-border-color);
  border-radius: 6px; /* Smaller radius for compactness */
  background: #3a3a5a;
  color: var(--td-primary-text);
  font-size: 0.9rem; /* Reduced font size for compactness */
}

.light-mode .token-details-modal-input-group input {
  background: #e0e0e0; /* Lighter background in light mode */
}

.token-details-modal-info-text {
  font-size: 0.75rem; /* Reduced font size for compactness */
  color: var(--td-secondary-text);
  margin-top: 4px; /* Reduced margin for compactness */
}

/* Confirmation Text */
.token-details-confirmation-text {
  margin-bottom: 12px; /* Reduced margin for compactness */
}

.token-details-confirmation-text p {
  margin: 4px 0; /* Reduced margin for compactness */
  font-size: 0.85rem; /* Reduced font size for compactness */
  color: var(--td-secondary-text);
}

.token-details-confirmation-text p strong {
  color: var(--td-accent-color);
  font-size: 0.9rem; /* Reduced font size for compactness */
}

.token-details-modal-reward-logo {
  width: 24px; /* Reduced size for compactness */
  height: 24px;
  border-radius: 6px; /* Smaller radius for compactness */
  margin: 4px 0; /* Reduced margin for compactness */
  object-fit: cover;
}

/* Modal Actions */
.token-details-action-modal-actions {
  display: flex;
  gap: 8px; /* Reduced gap for compactness */
  justify-content: center;
  margin-top: 12px; /* Reduced margin for compactness */
}

/* Transaction Status */
.token-details-transaction-status {
  margin-top: 8px; /* Reduced margin for compactness */
  font-size: 0.8rem; /* Reduced font size for compactness */
}

.token-details-transaction-status.success { color: #00cc00; }
.token-details-transaction-status.error { color: #ff4444; }

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

/* Mobile Adjustments (≤430px) */
@media (max-width: 430px) {
  .token-details-container {
    margin: 8px auto;
    padding: 12px;
  }
  .token-details-header {
    padding: 0;
    margin-bottom: 8px;
  }
  .token-details-main-token-logo {
    width: 48px;
    height: 48px;
    margin-bottom: 8px;
  }
  .token-details-page-title {
    font-size: 1.25rem;
  }
  .token-details-sections {
    gap: 8px;
  }
  .token-details-details-grid {
    grid-template-columns: 1fr;
    gap: 8px;
  }
  .token-details-card,
  .token-details-user-info {
    padding: 12px;
  }
  .token-details-detail-item {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .token-details-detail-label {
    font-size: 0.75rem;
  }
  .token-details-detail-value {
    font-size: 0.85rem;
  }
  .token-details-user-info-title {
    font-size: 1.2rem;
    margin-bottom: 8px;
  }
  .token-details-user-details-grid {
    grid-template-columns: 1fr;
    gap: 8px;
  }
  .token-details-user-detail {
    gap: 4px;
  }
  .token-details-user-detail-buttons {
    gap: 4px;
    margin-top: 4px;
    margin-bottom: 4px;
  }
  .token-details-reward-logo {
    width: 20px;
    height: 20px;
  }
  .token-details-reward-value {
    font-size: 0.75rem;
  }
  .token-details-no-rewards {
    font-size: 0.75rem;
  }
  .token-details-action-modal-content {
    padding: 12px;
    max-width: 280px;
    border-radius: 8px;
  }
  .token-details-action-modal-content h2 {
    font-size: 1.1rem;
    margin: 0 0 8px;
  }
  .token-details-modal-close {
    top: 6px;
    right: 6px;
    font-size: 1rem;
  }
  .token-details-modal-input-group {
    margin-bottom: 8px;
    width: 100%; /* Ensure full width within modal */
    box-sizing: border-box; /* Include padding in width calculation */
  }
  .token-details-modal-input-group label {
    font-size: 0.7rem;
    margin-bottom: 2px;
  }
  .token-details-modal-input-group input {
    width: 100%;
    padding: 6px; /* Match other mobile inputs */
    border: 1px solid var(--td-border-color);
    border-radius: 6px;
    background: #3a3a5a;
    color: var(--td-primary-text);
    font-size: 0.8rem;
    box-sizing: border-box; /* Include padding in width calculation */
    max-width: 100%; /* Prevent overflow */
    overflow: hidden; /* Clip content if it exceeds */
  }
  .light-mode .token-details-modal-input-group input {
    background: #e0e0e0; /* Ensure consistency in light mode */
  }
  .token-details-modal-info-text {
    font-size: 0.7rem;
    margin-top: 2px;
  }
  .token-details-confirmation-text {
    margin-bottom: 8px;
  }
  .token-details-confirmation-text p {
    margin: 2px 0;
    font-size: 0.75rem;
  }
  .token-details-confirmation-text p strong {
    font-size: 0.8rem;
  }
  .token-details-modal-reward-logo {
    width: 20px;
    height: 20px;
    margin: 2px 0;
    object-fit: cover;
  }
  .token-details-action-modal-actions {
    gap: 6px;
    margin-top: 8px;
  }
  .token-details-transaction-status {
    margin-top: 6px;
    font-size: 0.7rem;
  }
  .token-details-loading,
  .token-details-error {
    padding: 12px;
    font-size: 0.9rem;
  }
}