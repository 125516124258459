/* Global Theme Variables */
:root {
  --wp-bg-color: #121212;
  --wp-primary-text: #e0e0e0;
  --wp-secondary-text: #b0b0b0;
  --wp-accent-color: #8a2be2;
  --wp-accent-hover: #b72be2;
  --wp-card-bg: #1a1a1a;
  --wp-shadow-color: rgba(0, 0, 0, 0.5);
}

/* Light Mode Overrides */
.light-mode {
  --wp-bg-color: #ffffff;
  --wp-primary-text: #333333;
  --wp-secondary-text: #666666;
  --wp-accent-color: #6a1b9a; /* Softer purple for light mode */
  --wp-accent-hover: #8a2be2;
  --wp-card-bg: #f0f0f0;
  --wp-shadow-color: rgba(0, 0, 0, 0.1);
}

/* Container */
.wallet-purchases-page {
  padding: 20px;
  background-color: var(--wp-bg-color);
  color: var(--wp-primary-text);
  font-family: 'Space Mono', monospace;
}

/* Transaction Card */
.wallet-purchases-activity-item-link {
  text-decoration: none;
  color: inherit;
  display: block;
  margin-bottom: 20px;
}

.wallet-purchases-activity-item {
  background-color: var(--wp-card-bg);
  border-radius: 8px;
  padding: 15px;
  display: flex;
  align-items: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 2px 4px var(--wp-shadow-color);
}

.light-mode .wallet-purchases-activity-item {
  box-shadow: 0 2px 4px var(--wp-shadow-color);
}

.wallet-purchases-activity-item:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 8px var(--wp-shadow-color);
}

.light-mode .wallet-purchases-activity-item:hover {
  box-shadow: 0 4px 8px var(--wp-shadow-color);
}

/* NFT Image */
.wallet-purchases-listings-nft-image {
  width: 80px;
  height: 80px;
  border-radius: 5px;
  object-fit: cover;
  margin-right: 15px;
}

/* Transaction Details */
.wallet-purchases-activity-details {
  flex: 1;
}

.wallet-purchases-activity-details p {
  margin: 0;
  font-size: 1rem;
  line-height: 1.4;
  color: var(--wp-primary-text); /* Ensure "You bought" adapts to theme */
}

.light-mode .wallet-purchases-activity-details p {
  color: var(--wp-primary-text); /* #333 in light mode */
}

.wallet-purchases-date {
  color: var(--wp-secondary-text);
  font-size: 0.9rem;
}

.wallet-purchases-asset-id {
  font-weight: bold;
  color: var(--wp-primary-text);
}

.light-mode .wallet-purchases-asset-id {
  color: var(--wp-primary-text); /* #333 in light mode */
}

.wallet-purchases-listed-price {
  font-weight: bold;
  color: var(--wp-accent-color);
}

.wallet-purchases-marketplace-info {
  display: inline-flex;
  align-items: center;
  gap: 5px;
}

.wallet-purchases-marketplace {
  font-weight: bold;
  color: var(--wp-primary-text); /* Ensure marketplace name (e.g., "Rare Canvas") adapts */
}

.light-mode .wallet-purchases-marketplace {
  color: var(--wp-primary-text); /* #333 in light mode for better contrast */
}

.wallet-purchases-marketplace-image {
  width: 16px;
  height: 16px;
}

/* Pagination */
.wallet-purchases-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 30px;
}

.wallet-purchases-pagination button {
  background: linear-gradient(45deg, var(--wp-accent-color), var(--wp-accent-hover));
  border: none;
  padding: 8px 16px;
  border-radius: 20px;
  color: #fff; /* Default for dark mode */
  cursor: pointer;
  transition: background 0.3s ease;
}

.light-mode .wallet-purchases-pagination button {
  color: var(--wp-primary-text); /* #333 in light mode for better contrast */
}

.wallet-purchases-pagination button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.light-mode .wallet-purchases-pagination button:disabled {
  background: #b0b0b0; /* Lighter gray in light mode */
  color: #666; /* Better contrast */
}

.wallet-purchases-pagination span {
  font-size: 1rem;
  color: var(--wp-primary-text);
}

/* Responsive Adjustments */
@media (max-width: 430px) {
  .wallet-purchases-activity-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .wallet-purchases-listings-nft-image {
    width: 30%;
    height: auto;
    display: block;
    margin: 0 auto 10px;
  }

  .wallet-purchases-activity-details p {
    font-size: 0.9rem;
  }

  .wallet-purchases-pagination button {
    padding: 6px 12px;
    font-size: 0.9rem;
  }

  .wallet-purchases-pagination span {
    font-size: 0.9rem;
  }
}