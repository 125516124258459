/* Custom Theme Variables */
:root {
  /* Default (dark mode) */
  --sw-bg-color: #1e1e33;
  --sw-primary-text: #ffffff;
  --sw-secondary-text: #a0a0c0;
  --sw-accent-color: #8a2be2;
  --sw-accent-hover: #b72be2;
  --sw-border-color: rgba(255, 255, 255, 0.1);
  --sw-card-bg: #2d2d4a;
  --sw-shadow: 0 8px 20px rgba(0, 0, 0, 0.5);
}

.light-mode {
  /* Light mode overrides */
  --sw-bg-color: #ffffff;
  --sw-primary-text: #333333; /* Darker text for readability */
  --sw-secondary-text: #666666; /* Medium gray for secondary text */
  --sw-accent-color: #6a1b9a; /* Softer purple for light mode */
  --sw-accent-hover: #8a2be2;
  --sw-border-color: rgba(0, 0, 0, 0.1);
  --sw-card-bg: #f0f0f0; /* Light gray card background */
  --sw-shadow: 0 8px 20px rgba(0, 0, 0, 0.1); /* Lighter shadow */
}

/* Container */
.swap-container {
  width: 100%;
  max-width: 480px;
  margin: 40px auto;
  padding: 32px;
  background: linear-gradient(135deg, var(--sw-bg-color), var(--sw-card-bg));
  border-radius: 20px;
  box-shadow: var(--sw-shadow);
  color: var(--sw-primary-text);
  font-family: 'Inter', Arial, sans-serif;
  box-sizing: border-box;
  transition: all 0.3s ease; /* Smooth transition for theme changes */
}

.light-mode .swap-container {
  background: linear-gradient(135deg, var(--sw-bg-color), #e0e0e0); /* Softer light gradient */
  box-shadow: var(--sw-shadow); /* Ensure shadow adapts */
}

/* Header */
.swap-header {
  margin-bottom: 24px;
  padding: 0 16px;
}

.swap-title {
  font-size: 2rem;
  font-weight: 700;
  margin: 0 0 16px;
  color: var(--sw-primary-text);
  text-align: center;
}

.light-mode .swap-title {
  color: var(--sw-primary-text); /* Explicitly enforce #333333 in light mode */
}

.tab-header {
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-bottom: 16px;
}

.tab-button {
  flex-grow: 1;
  padding: 12px;
  background: var(--sw-card-bg);
  color: var(--sw-primary-text);
  border: 1px solid var(--sw-border-color);
  border-radius: 12px;
  cursor: pointer;
  font-size: 1rem;
  transition: background 0.3s ease, border-color 0.3s ease;
}

.tab-button.active {
  background: var(--sw-accent-color);
  border-color: var(--sw-accent-hover);
  color: var(--sw-primary-text);
}

.tab-button:hover:not(.active) {
  background: rgba(255, 255, 255, 0.1); /* Dark mode hover effect */
}

.light-mode .tab-button:hover:not(.active) {
  background: rgba(0, 0, 0, 0.05); /* Lighter hover effect in light mode */
}

/* Content */
.swap-content,
.trade-history-content {
  background: var(--sw-card-bg);
  padding: 24px;
  border-radius: 16px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  animation: fadeIn 0.5s ease;
}

.light-mode .swap-content,
.light-mode .trade-history-content {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05); /* Lighter shadow in light mode */
}

/* Balance Info */
.swap-balance-info {
  margin-bottom: 24px;
  padding: 16px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
}

.light-mode .swap-balance-info {
  background: rgba(0, 0, 0, 0.05); /* Subtle gray background in light mode */
}

.swap-balance-info h3 {
  font-size: 1.25rem;
  margin: 0 0 12px;
  color: var(--sw-primary-text);
}

.light-mode .swap-balance-info h3 {
  color: var(--sw-primary-text); /* Explicitly enforce #333333 in light mode */
}

.token-balances {
  display: flex;
  justify-content: space-between;
  gap: 12px;
}

.light-mode .token-balances {
  color: var(--sw-primary-text); /* Ensure text is #333333 (black) in light mode */
}

.token-balance {
  display: flex;
  align-items: center;
  gap: 8px;
}

.light-mode .token-balance {
  color: var(--sw-primary-text); /* Explicitly enforce #333333 in light mode */
}

.token-logo {
  width: 32px;
  height: 32px;
  border-radius: 8px;
  object-fit: cover;
}

.token-balance span {
  font-size: 1rem;
  color: var(--sw-primary-text); /* Ensure dark text in light mode */
}

/* Swap Form */
.swap-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.swap-row {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.swap-row label {
  font-size: 0.9rem;
  color: var(--sw-secondary-text);
  text-transform: uppercase;
}

.token-selection {
  display: flex;
  align-items: center;
  padding: 12px;
  background: var(--sw-card-bg);
  border-radius: 12px;
  border: 1px solid var(--sw-border-color);
  gap: 12px;
}

.token-info {
  display: flex;
  align-items: center;
  gap: 8px;
}

.token-label {
  font-size: 1rem;
  font-weight: 600;
  color: var(--sw-primary-text);
}

.balance-info {
  font-size: 0.9rem;
  color: var(--sw-secondary-text);
  margin-left: auto;
}

.swap-input {
  width: 100%;
  padding: 12px;
  border: 1px solid var(--sw-border-color);
  border-radius: 12px;
  background: var(--sw-card-bg);
  color: var(--sw-primary-text);
  font-size: 1rem;
  box-sizing: border-box;
}

.swap-input:focus {
  outline: none;
  border-color: var(--sw-accent-color);
}

.swap-input:disabled {
  background: rgba(255, 255, 0.1); /* Dark mode disabled background */
  cursor: not-allowed;
  opacity: 0.7;
}

.light-mode .swap-input:disabled {
  background: rgba(0, 0, 0, 0.05); /* Lighter disabled background in light mode */
}

/* Switch Button */
.switch-button {
  padding: 8px 16px;
  background: var(--sw-card-bg);
  color: var(--sw-primary-text);
  border: 1px solid var(--sw-accent-color);
  border-radius: 8px;
  font-size: 0.9rem;
  font-weight: 600;
  cursor: pointer;
  transition: background 0.2s ease, border-color 0.2s ease;
  margin: 12px auto;
  display: flex;
  align-items: center;
  gap: 8px;
}

.switch-button:hover {
  background: rgba(255, 255, 255, 0.1); /* Dark mode hover effect */
  border-color: var(--sw-accent-hover);
}

.light-mode .switch-button:hover {
  background: rgba(0, 0, 0, 0.05); /* Lighter hover effect in light mode */
}

/* Percentage Buttons */
.percentage-buttons {
  display: flex;
  gap: 8px;
  margin-top: 12px;
}

.percent-button {
  flex: 1;
  padding: 8px 12px;
  background: var(--sw-card-bg);
  color: var(--sw-primary-text);
  border: 1px solid var(--sw-accent-color);
  border-radius: 8px;
  font-size: 0.9rem;
  font-weight: 600;
  cursor: pointer;
  transition: background 0.2s ease, border-color 0.2s ease;
}

.percent-button:hover {
  background: rgba(255, 255, 255, 0.1); /* Dark mode hover effect */
  border-color: var(--sw-accent-hover);
}

.light-mode .percent-button:hover {
  background: rgba(0, 0, 0, 0.05); /* Lighter hover effect in light mode */
}

/* Exchange Rate and Fee Details */
.exchange-rate,
.fee-details {
  margin-top: 16px;
  padding: 12px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  font-size: 0.9rem;
  color: var(--sw-secondary-text);
}

.light-mode .exchange-rate,
.light-mode .fee-details {
  background: rgba(0, 0, 0, 0.05); /* Subtle gray background in light mode */
}

.fee-details p {
  margin: 4px 0;
}

.receive-amount {
  color: var(--sw-accent-color);
  font-size: 1.1rem;
  font-weight: 600;
}

/* Swap Button */
.swap-button-swap {
  width: 100%;
  padding: 12px 20px;
  background: var(--sw-card-bg);
  color: var(--sw-primary-text);
  border: 1px solid var(--sw-accent-color);
  border-radius: 12px;
  font-size: 1rem;
  font-weight: 700;
  cursor: pointer;
  transition: background 0.2s ease, border-color 0.2s ease;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.swap-button-swap:hover:not(:disabled) {
  background: rgba(255, 255, 255, 0.1); /* Dark mode hover effect */
  border-color: var(--sw-accent-hover);
}

.light-mode .swap-button-swap:hover:not(:disabled) {
  background: rgba(0, 0, 0, 0.05); /* Lighter hover effect in light mode */
}

.swap-button-swap:disabled {
  background: rgba(255, 255, 255, 0.1); /* Dark mode disabled background */
  border-color: var(--sw-border-color);
  cursor: not-allowed;
  color: var(--sw-secondary-text);
}

.light-mode .swap-button-swap:disabled {
  background: rgba(0, 0, 0, 0.05); /* Lighter disabled background in light mode */
}

/* Transaction Status */
.transaction-status-swap {
  margin-top: 16px;
  font-size: 0.9rem;
  color: var(--sw-accent-color);
  text-align: center;
}

/* Loading Overlay */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.light-mode .loading-overlay {
  background: rgba(0, 0, 0, 0.5); /* Lighter overlay in light mode */
}

/* Trade History */
.trade-history-content {
  background: var(--sw-card-bg);
  padding: 24px;
  border-radius: 16px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.trade-history-content h3 {
  font-size: 1.5rem;
  margin: 0 0 16px;
  color: var(--sw-primary-text);
  text-align: center;
}

.light-mode .trade-history-content h3 {
  color: var(--sw-primary-text); /* Explicitly enforce #333333 in light mode */
}

.transaction-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.transaction-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
  border-bottom: 1px solid var(--sw-border-color);
  font-size: 0.9rem;
  color: var(--sw-primary-text);
}

.transaction-time {
  color: var(--sw-secondary-text);
}

.transaction-type {
  font-weight: 600;
}

.buy { color: #00cc00; }
.sell { color: #ff4444; }

.loading-spinner,
.no-history {
  text-align: center;
  padding: 16px;
  font-size: 1rem;
  color: var(--sw-secondary-text);
}

/* Loading Dots */
.loading-dots {
  animation: blink 1s infinite;
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}

@keyframes blink {
  50% { opacity: 0.5; }
}

/* Responsive Design (≤430px) */
@media (max-width: 430px) {
  .swap-container {
    margin: 16px auto;
    padding: 20px;
    max-width: 100%;
  }

  .swap-header {
    padding: 0;
  }

  .swap-title {
    font-size: 1.5rem;
  }

  .tab-header {
    gap: 4px;
  }

  .tab-button {
    padding: 8px;
    font-size: 0.9rem;
  }

  .swap-content,
  .trade-history-content {
    padding: 16px;
  }

  .swap-balance-info {
    padding: 12px;
  }

  .swap-balance-info h3 {
    font-size: 1rem;
  }

  .token-logo {
    width: 24px;
    height: 24px;
  }

  .token-balance span {
    font-size: 0.9rem;
  }

  .swap-row label {
    font-size: 0.8rem;
  }

  .token-selection {
    padding: 10px;
  }

  .token-label {
    font-size: 0.9rem;
  }

  .balance-info {
    font-size: 0.8rem;
  }

  .swap-input {
    padding: 8px;
    font-size: 0.9rem;
  }

  .switch-button,
  .percent-button {
    padding: 6px 12px;
    font-size: 0.8rem;
  }

  .exchange-rate,
  .fee-details,
  .transaction-status-swap {
    font-size: 0.8rem;
    padding: 8px;
    color: var(--sw-secondary-text); /* Ensure color adapts */
  }

  .receive-amount {
    font-size: 1rem;
  }

  .swap-button-swap {
    padding: 8px 16px;
    font-size: 0.9rem;
  }

  .trade-history-content h3 {
    font-size: 1.25rem;
  }

  .transaction-item {
    padding: 8px 0;
    font-size: 0.8rem;
  }
}