/* Theme Variables (Consistent with HomePage) */
:root {
  /* Default (dark mode) */
  --c-bg-color: #0f0f1a;
  --c-primary-text: #e0e0ff;
  --c-secondary-text: #a0a0d0;
  --c-accent-color: #7b00ff;
  --c-accent-hover: #9b3bff;
  --c-border-color: rgba(255, 255, 255, 0.1);
  --c-card-bg: #252540;
  --c-card-gradient: linear-gradient(135deg, #2a2a4f, #252540);
  --c-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  --c-gradient: linear-gradient(90deg, #6b48ff, #8a2be2);
}

/* Light Mode Overrides */
.light-mode {
  --c-bg-color: #ffffff;
  --c-primary-text: #333333;
  --c-secondary-text: #666666;
  --c-accent-color: #6a1b9a; /* Softer purple for light mode */
  --c-accent-hover: #8a2be2;
  --c-border-color: rgba(0, 0, 0, 0.1);
  --c-card-bg: #f0f0f0;
  --c-card-gradient: linear-gradient(135deg, #e0e0e0, #f0f0f0);
  --c-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  --c-gradient: linear-gradient(90deg, #d0d0d0, #e0e0e0); /* Gray gradient for light mode */
}

/* Base Container */
.mint-container {
  background: var(--c-bg-color);
  min-height: 100vh;
  padding: 40px 20px;
  color: var(--c-primary-text);
  font-family: 'Inter', Arial, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Page Title */
.mint-title {
  font-size: 2.5rem;
  font-weight: 700;
  color: var(--c-primary-text);
  margin-bottom: 30px;
  text-align: center;
  text-transform: uppercase;
}

/* Mint Grid */
.mint-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  width: 90%;
  max-width: 1200px;
}

/* Mint Card */
.mint-card {
  background: var(--c-card-bg);
  border-radius: 12px;
  padding: 15px;
  border: 1px solid var(--c-border-color);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.mint-card:hover {
  transform: translateY(-5px);
  box-shadow: var(--c-shadow);
}

.mint-card-image {
  position: relative;
  width: 100%;
  height: 180px;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 10px;
}

.mint-logo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.mint-logo-placeholder {
  width: 100%;
  height: 100%;
  background: #1a1a2f; /* Dark mode */
}

.light-mode .mint-logo-placeholder {
  background: #d0d0d0; /* Light mode */
}

.mint-card-content {
  text-align: center;
}

.mint-card-title {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--c-primary-text);
  margin: 0 0 8px;
}

.mint-card-info,
.mint-card-price {
  font-size: 0.95rem;
  color: var(--c-secondary-text);
  margin: 0 0 8px;
}

.mint-button {
  background: var(--c-gradient);
  color: var(--c-primary-text);
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  font-weight: 600;
  font-size: 1rem;
  cursor: pointer;
  width: 100%;
  transition: background 0.3s ease, transform 0.2s ease;
}

.light-mode .mint-button {
  background: var(--c-gradient); /* Use gray gradient defined in light mode */
  color: #333333; /* Dark text for contrast */
}

.mint-button:hover {
  background: var(--c-accent-hover);
  transform: translateY(-2px);
}

.light-mode .mint-button:hover {
  background: linear-gradient(90deg, #c0c0c0, #d0d0d0); /* Slightly darker gray gradient on hover */
  transform: translateY(-2px);
}

.mint-button:disabled {
  background: #4a4a6a; /* Dark mode */
}

.light-mode .mint-button:disabled {
  background: #b0b0b0; /* Light mode */
}

/* No ITOs Message */
.no-itos {
  font-size: 1.2rem;
  color: var(--c-secondary-text);
  text-align: center;
  padding: 20px;
}

/* Mint Modal */
.mint-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8); /* Dark mode */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.light-mode .mint-modal {
  background: rgba(0, 0, 0, 0.5); /* Lighter overlay in light mode */
}

.mint-modal-content {
  background: var(--c-card-gradient);
  border-radius: 12px;
  padding: 20px;
  width: 90%;
  max-width: 400px;
  border: 1px solid var(--c-border-color);
  box-shadow: var(--c-shadow);
  animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
  from { opacity: 0; transform: scale(0.95); }
  to { opacity: 1; transform: scale(1); }
}

.mint-modal-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--c-primary-text);
  margin: 0 0 15px;
  text-align: center;
}

.mint-modal-image {
  width: 120px;
  height: 120px;
  margin: 0 auto 15px;
}

.mint-modal-info {
  font-size: 0.95rem;
  color: var(--c-secondary-text);
  margin: 5px 0;
  text-align: center;
}

.mint-modal-price {
  font-size: 1.1rem;
  font-weight: 600;
  color: var(--c-accent-color);
  margin: 10px 0;
}

.mint-modal-balance {
  font-size: 0.95rem;
  color: var(--c-secondary-text);
  margin: 5px 0;
}

.pack-selector {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  margin: 10px 0;
}

.pack-nav-button {
  background: #1a1a2f; /* Dark mode */
  border: none;
  color: var(--c-primary-text);
  width: 35px;
  height: 35px;
  border-radius: 50%;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background 0.3s ease;
}

.light-mode .pack-nav-button {
  background: #d0d0d0; /* Light mode */
  color: #333333; /* Dark text for contrast */
}

.pack-nav-button:hover {
  background: var(--c-accent-color);
}

.light-mode .pack-nav-button:hover {
  background: #c0c0c0; /* Slightly darker gray on hover */
}

.pack-nav-button:disabled {
  background: #2a2a4f; /* Dark mode */
}

.light-mode .pack-nav-button:disabled {
  background: #b0b0b0; /* Light mode */
}

.pack-amount {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--c-primary-text);
}

.mint-confirm-button {
  background: var(--c-gradient);
  color: var(--c-primary-text);
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  font-weight: 600;
  font-size: 1rem;
  cursor: pointer;
  width: 100%;
  margin-top: 10px;
  transition: background 0.3s ease, transform 0.2s ease;
}

.light-mode .mint-confirm-button {
  background: var(--c-gradient); /* Use gray gradient defined in light mode */
  color: #333333; /* Dark text for contrast */
}

.mint-confirm-button:hover {
  background: var(--c-accent-hover);
  transform: translateY(-2px);
}

.light-mode .mint-confirm-button:hover {
  background: linear-gradient(90deg, #c0c0c0, #d0d0d0); /* Slightly darker gray gradient on hover */
  transform: translateY(-2px);
}

.mint-confirm-button:disabled {
  background: #4a4a6a; /* Dark mode */
}

.light-mode .mint-confirm-button:disabled {
  background: #b0b0b0; /* Light mode */
}

.mint-cancel-button {
  background: #1a1a2f; /* Dark mode */
  color: var(--c-primary-text);
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  font-weight: 600;
  font-size: 1rem;
  cursor: pointer;
  width: 100%;
  margin-top: 10px;
  transition: background 0.3s ease;
}

.light-mode .mint-cancel-button {
  background: #d0d0d0; /* Light mode */
  color: #333333; /* Dark text for contrast */
}

.mint-cancel-button:hover {
  background: #3a3a6f; /* Dark mode */
}

.light-mode .mint-cancel-button:hover {
  background: #c0c0c0; /* Slightly darker gray on hover */
}

.transaction-status {
  font-size: 0.9rem;
  color: var(--c-accent-color);
  margin-top: 10px;
  text-align: center;
}

.modal-open {
  overflow: hidden;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .mint-container {
    padding: 30px 15px;
  }

  .mint-title {
    font-size: 2rem;
  }

  .mint-grid {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }

  .mint-card-image {
    height: 150px;
  }

  .mint-card-title {
    font-size: 1.1rem;
  }

  .mint-card-info,
  .mint-card-price {
    font-size: 0.9rem;
  }
}

@media (max-width: 430px) {
  .mint-container {
    padding: 20px 10px;
  }

  .mint-title {
    font-size: 1.5rem;
  }

  .mint-grid {
    grid-template-columns: 1fr;
    width: 100%;
  }

  .mint-card {
    padding: 10px;
  }

  .mint-card-image {
    height: 120px;
  }

  .mint-card-title {
    font-size: 1rem;
  }

  .mint-card-info,
  .mint-card-price {
    font-size: 0.85rem;
  }

  .mint-button {
    padding: 8px 16px;
    font-size: 0.9rem;
  }

  .mint-modal-content {
    padding: 15px;
  }

  .mint-modal-title {
    font-size: 1.3rem;
  }

  .mint-modal-image {
    width: 100px;
    height: 100px;
  }

  .mint-modal-info {
    font-size: 0.9rem;
  }

  .mint-modal-price {
    font-size: 1rem;
  }

  .mint-modal-balance {
    font-size: 0.85rem;
  }

  .pack-nav-button {
    width: 30px;
    height: 30px;
    font-size: 1rem;
  }

  .pack-amount {
    font-size: 1.1rem;
  }

  .mint-confirm-button,
  .mint-cancel-button {
    padding: 8px 16px;
    font-size: 0.9rem;
  }

  .transaction-status {
    font-size: 0.85rem;
  }
}